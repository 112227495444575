import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import LoginPage from './pages/LoginPage';
import routes from './router/routes';
import Protected from './pages/Protected';
import TraderListing from './pages/Listing/traders/TraderListing';
import CompanyData from './pages/Listing/companyData/CompanyData';
import CompanyDataV2 from './pages/Listing/companyData/CompanyDataV2';
import Form from './components/Form/Form';
import Settings from './pages/Listing/settings/Settings';
import './App.css';

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage('light');
  const toggleColorScheme = (value) => {
    setColorScheme(value || colorScheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <NotificationsProvider>
          <div className={colorScheme === 'dark' ? 'dark' : ''}>
            <BrowserRouter>
              <Routes>
                <Route
                  path={routes.BALANCE_SHEET_DETAIL}
                  exact
                  element={(
                    <Protected>
                      <CompanyDataV2 />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.TRADERS_DATA_LISTING}
                  exact
                  element={(
                    <Protected>
                      <TraderListing />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.TRADERS_DATA_DETAIL}
                  exact
                  element={(
                    <Protected>
                      <TraderListing />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.COMPANY_DATA_V2}
                  exact
                  element={(
                    <Protected>
                      <CompanyDataV2 />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.COMPANY_DATA}
                  exact
                  element={(
                    <Protected>
                      <CompanyData />
                      {/* <CompanyDataV2/> */}
                    </Protected>
                  )}
                />
                <Route
                  path={routes.COMPANY_BOOK_DETAIL}
                  exact
                  element={(
                    <Protected>
                      <CompanyDataV2 />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.BONUS_POOL_DETAIL}
                  exact
                  element={(
                    <Protected>
                      <CompanyDataV2 />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.FORM}
                  exact
                  element={(
                    <Protected>
                      <Form />
                    </Protected>
                  )}
                />
                <Route
                  path={routes.SETTINGS}
                  exact
                  element={(
                    <Protected>
                      <Settings />
                    </Protected>
                  )}
                />
                <Route
                  path="/"
                  exact
                  element={<LoginPage />}
                />
              </Routes>
            </BrowserRouter>
          </div>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
