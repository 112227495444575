import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const adminLogin = ({
  accessToken,
}) => axios.post(`${constants.tradersApiAuthHost}/admin/login`, {
  access_token: accessToken,
}, {
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const userDetailsApi = () => axios.get(`${constants.tradersApiAuthHost}/admin/details`, {
  noTrailingSlash: true,
  headers: headersProvider(),
});
