/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { CurrencyDollar, LayoutList } from 'tabler-icons-react';
import {
  Loader, ScrollArea, Table, Tabs,
  useMantineColorScheme,
} from '@mantine/core';
import { DistributionDummy } from './BalanceSheetDummy';
import SingleLineBarChart from './SingleLineBarChart';
import Styles from './BalanceSheet.module.css';
import { assetPrefix, loadingStates, renderNumber } from '../../../../utilities/general';

export default function DistributionChart({
  type, data, keyName, dataLoading,
}) {
  const { colorScheme } = useMantineColorScheme();
  const [activeTab, setActiveTab] = useState('crypto');
  const [subActiveTab, setSubActiveTab] = useState('asset');
  const [tableData, setTableData] = useState();

  const currentMonth = data[keyName]?.distribution?.current.map((item) => ({
    share: item.share,
    Now: item.value,
  }));

  const finalCurrentVSsixMonth = data[keyName]?.distribution?.sixMonthsBack.map((item) => {
    const forexObject = currentMonth.find((val) => val.share === item?.share);
    return { ...forexObject, '6 Months Ago': item.value };
  }) || [];

  const tableChartData = [{}];
  const structureData = (arrData) => {
    const result = [];
    const assetTypes = [...new Set(arrData?.map((item) => item.typeOfAsset))];

    assetTypes.forEach((assetType) => {
      const assetTypeData = {};
      assetTypeData[assetType] = [];

      const assetSubtypes = ['asset', 'trader', 'location'];

      assetSubtypes.forEach((subtype) => {
        const subtypeData = {};
        subtypeData[subtype] = [];
        const key = subtype === 'asset' ? 'Quantity' : 'Asset';

        arrData?.forEach((item) => {
          if (item.typeOfAsset === assetType) {
            const entry = {
              [subtype]: item[subtype],
              [key]: subtype === 'asset' ? item.quantity : item.asset,
              USD: item.value,
            };
            subtypeData[subtype].push(entry);
          }
        });

        assetTypeData[assetType].push(subtypeData);
      });

      result.push(assetTypeData);
    });

    return result;
  };

  useEffect(() => {
    setTableData(structureData(data[keyName]?.distribution?.details));
  }, [data]);
  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
          payload.map((entry, index) => (
            <div className="flex items-center gap-1.5">
              <span
                style={{
                  backgroundColor: entry.color,
                }}
                className="h-3.5 w-3.5 rounded-[3px]"
              />
              <span key={`item-${index}`} className=" flex font-normal not-italic text-sm text-gray-700 dark:text-slate-400">{entry.value}</span>
            </div>
          ))
        }
      </>
    );
  };
  const numVerticalGridLines = DistributionDummy.length + 1;

  const verticalGridPositions = (props) => {
    const w = parseInt((props.offset.width - 75) / 5);
    return Array.from({ length: numVerticalGridLines }, (_, index) => index * w + 128);
  };
  const DataFormater = (number, dec) => {
    if (Math.abs(number) >= 1000000000) {
      return `${(number / 1000000000).toFixed(typeof dec !== 'number' ? 2 : 0)}B`;
    } if (Math.abs(number) >= 1000000) {
      return `${(number / 1000000).toFixed(typeof dec !== 'number' ? 2 : 0)}M`;
    } if (Math.abs(number) >= 1000) {
      return `${(number / 1000).toFixed(typeof dec !== 'number' ? 2 : 0)}K`;
    }
    return Number.isInteger(number) ? number.toFixed(0) : number.toString();
  };
  return (
    <>
      <div className="py-8">
        <div className="flex justify-evenly gap-4 border dark:border-neutral-600 dark:divide-neutral-600 divide-x min-h-[6.5rem] items-center">
          {dataLoading === loadingStates.LOADING
            && <div><Loader variant="dots" /></div>}
          {dataLoading === loadingStates.FINISHED
            && data[keyName]?.distribution?.current.map((item) => (
              <div className="flex justify-center w-full">
                <div className="grid grid-rows-2 py-4">
                  <span className="flex justify-center font-medium text-base not-italic">{item.share ? item.share : '-'}</span>
                  <span className="flex justify-center font-bold text-[#003A6D] text-2xl">{renderNumber(item.value, true)}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        <SingleLineBarChart type={type} data={data[keyName]?.distribution?.current} tableChart={false} dataLoading={dataLoading} />
      </div>
      <div className="border border-slate-100 dark:border-neutral-600 h-full mt-7">
        <div className="pt-6 px-7">
          <span className={`${Styles.BalanceSheetTitle} text-[#333333] dark:text-inherit flex items-center gap-4`}>
            Distribution Now vs  6 Months Ago
          </span>
        </div>
        <div>
          <ResponsiveContainer width="99%" aspect={1.2}>
            <BarChart
              width={500}
              height={200}
              data={finalCurrentVSsixMonth}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid verticalCoordinatesGenerator={verticalGridPositions} />
              <XAxis type="category" dataKey="share" tickLine={false} />
              <YAxis domain={[0, 'auto']} tickFormatter={DataFormater} />
              <Tooltip
                cursor={{
                  fill: 'none', stroke: 'gray', strokeDasharray: '3 3', strokeWidth: 2,
                }}
                formatter={(props) => `${(DataFormater(props, true))}`}
              />
              <Legend
                content={renderLegend}
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  margin: '8px 25px',
                  top: '0rem',
                  paddingBottom: '29px',
                  display: 'flex',
                  gap: 12,
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
                iconType="square"
                iconSize={12}
              />

              <Bar dataKey="Now" fill="#026DB0" />
              <Bar dataKey="6 Months Ago" fill="#F9A960" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={`${type === 'Liabilities Distribution' ? 'opacity-20' : ''}`}>
        <div>
          <div className="py-5 px-0 pl-[32px] relative mt-8">
            <Tabs
              value={activeTab}
              onTabChange={setActiveTab}
              orientation="vertical"
              variant="pills"
              placement="left"
              className="pl-6"
              sx={{
                '& button[data-active]': {
                  backgroundColor: colorScheme === 'dark' ? 'grey' : '#0072C3',
                  color: colorScheme === 'dark' && 'floralwhite',
                  '&:hover': {
                    backgroundColor: colorScheme === 'dark' ? 'grey' : '#0072C3',
                  },
                },
              }}
            >
              {tableData?.map((obj) => (
                <>
                  <Tabs.List>
                    <Tabs.Tab className="rounded-none mr-1 rounded-tl-[12px] w-[111px] text-center justify-center rounded-tr-[12px] bg-[#EFF6FF] dark:bg-gray-800 text-[#58667E] text-[15px]" value={Object.keys(obj)[0]}>{Object.keys(obj)[0]?.charAt(0)?.toUpperCase() + Object.keys(obj)[0]?.slice(1)}</Tabs.Tab>
                  </Tabs.List>
                </>

              ))}
            </Tabs>
            <Tabs
              value={subActiveTab}
              onTabChange={setSubActiveTab}
              orientation="horizontal"
              variant="pills"
              // color="orange"
              sx={{
                '& button[data-active]': {
                  backgroundColor: colorScheme === 'dark' ? 'grey' : '#F9A960',
                  color: colorScheme === 'dark' && 'floralwhite',
                  '&:hover': {
                    backgroundColor: colorScheme === 'dark' ? 'grey' : '#F9A960',
                  },
                },
              }}
            >
              <div className="flex flex-row-reverse rotate-[270deg] origin-left absolute top-[393px] 2xl:top-[406px] left-[15px]">
                {tableData?.map((obj) => (

                  activeTab === Object.keys(obj)[0] && obj[activeTab]?.map((subData) => (
                    <Tabs.List>
                      <Tabs.Tab className="rounded-none mr-1 rounded-tl-[18px] w-[111px] rounded-tr-[18px] bg-[#EFF6FF] dark:bg-gray-800 text-[#58667E] text-[15px]" value={Object?.keys(subData)[0]}>{Object?.keys(subData)[0]?.charAt(0).toUpperCase() + Object?.keys(subData)[0]?.slice(1)}</Tabs.Tab>
                    </Tabs.List>
                  ))
                ))}
              </div>
              <div className="border border-slate-100 dark:border-neutral-600 px-5 py-5 rounded-md">
                <div className="min-h-[420px]">
                  {dataLoading === loadingStates.LOADING
                    && (
                      <div className="flex min-h-[20rem] justify-center items-center">
                        <Loader variant="dots" />
                      </div>
                    )}
                  {dataLoading === loadingStates.FINISHED
                    && (
                      <ScrollArea style={{ maxHeight: 420 }} scrollbarSize={5}>
                        <Table
                          withBorder
                          horizontalSpacing="xl"
                          verticalSpacing="lg"
                          sx={{
                            '& thead:before': {
                              backgroundColor: colorScheme === 'dark' && '#5c5a5a',
                            },
                            '& thead:after': {
                              backgroundColor: colorScheme === 'dark' && '#5c5a5a',
                            },
                            '& tbody tr td': {
                              borderColor: colorScheme === 'dark' && '#5c5a5a',
                            },
                          }}
                        >
                          {tableData?.map((obj) => (
                            <>
                              {activeTab === Object.keys(obj)[0] && obj[activeTab].map((subData) => (
                                <>
                                  {subActiveTab === Object.keys(subData)[0] && subData[subActiveTab].map((finalData, i) => {
                                    const key = finalData[Object.keys(finalData)[0]];
                                    const value = finalData[Object.keys(finalData)[2]];
                                    // tableChartData[0][finalData[Object.keys(finalData)[0]]] = finalData[Object.keys(finalData)[2]];
                                    if (tableChartData[0][key] !== undefined) {
                                      tableChartData[0][key] += value;
                                    } else {
                                      tableChartData[0][key] = value;
                                    }
                                    return (
                                      <>
                                        {i === 0 && (
                                          <>
                                            <thead className={`${Styles.TableHeading} ${Styles.TableHeadingBorder} font-medium sticky top-0 bg-white`}>
                                              <tr className="border-b-2">
                                                {/* {Object.keys(finalData).map((heading) => (
                                        <th>{heading.charAt(0).toUpperCase() + heading.slice(1)}</th>
                                      ))} */}
                                                <th>
                                                  <span className="flex gap-2 items-center border-none dark:text-neutral-400">
                                                    <img
                                                      src={`${assetPrefix}/images/calendar.svg`}
                                                      alt="calendar"
                                                      className="max-w-fit w-5 h-5 relative"
                                                    />
                                                    {Object.keys(finalData)[0]?.charAt(0).toUpperCase() + Object.keys(finalData)[0].slice(1)}
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="flex gap-2 items-center border-none dark:text-neutral-400">
                                                    <LayoutList
                                                      size={18}
                                                      strokeWidth={2.2}
                                                      color={colorScheme === 'dark' ? 'grey' : 'black'}
                                                    />
                                                    { Object.keys(finalData)[1]?.charAt(0).toUpperCase() + Object.keys(finalData)[1].slice(1) }
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="flex gap-2 items-center border-none dark:text-neutral-400">
                                                    <CurrencyDollar
                                                      size={18}
                                                      strokeWidth={2.2}
                                                      color={colorScheme === 'dark' ? 'grey' : 'black'}
                                                    />
                                                    {Object.keys(finalData)[2]?.charAt(0).toUpperCase() + Object.keys(finalData)[2].slice(1)}
                                                  </span>
                                                </th>

                                              </tr>
                                            </thead>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              ))}
                            </>
                          ))}
                          <tbody className={`${Styles.TableHeading} font-normal w-full`}>
                            {tableData?.map((obj) => (
                              <>
                                {activeTab === Object.keys(obj)[0] && obj[activeTab].map((subData) => (
                                  <>
                                    {subActiveTab === Object.keys(subData)[0] && subData[subActiveTab].map((finalData) => (
                                      <tr key={finalData.Assets} className="border-b dark:border-neutral-600">
                                        <td>{finalData[Object.keys(finalData)[0]]}</td>
                                        <td>{typeof (finalData[Object.keys(finalData)[1]]) === 'string' ? finalData[Object.keys(finalData)[1]] : finalData[Object.keys(finalData)[1]].toFixed(2)}</td>
                                        <td>{renderNumber(finalData[Object.keys(finalData)[2]], true)}</td>
                                      </tr>
                                    ))}
                                  </>
                                ))}
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </ScrollArea>
                    )}
                </div>
                <div className={`${type === 'Liabilities Distribution' ? 'hidden' : ''}`}>
                  <SingleLineBarChart type={`${subActiveTab.charAt(0).toUpperCase()}${subActiveTab.slice(1)} Distribution`} tableChartData={tableChartData} tableChart dataLoading={dataLoading} />
                </div>
              </div>

            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
