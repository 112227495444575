/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { Text } from '@mantine/core';
import Styles from './common.module.css';

export default function DistributionBarChart({ type, data }) {
  const [finalData, setFinalData] = useState();
  const assetColor = ['#003A6D', '#026DB0', '#30BEF5'];
  const liabilitiesColor = ['#E07800', '#FC9B41', '#FFD2AC'];
  const arr = [{}];

  data?.forEach((items) => {
    arr[0][items.share] = items.percentage * 100;
  });
  arr.push({});

  useEffect(() => {
    setFinalData(arr);
  }, [data]);

  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
              payload.map((entry, index) => (
                <div className="flex items-center gap-1">
                  <span
                    style={{
                      backgroundColor: entry.color,
                    }}
                    className="h-2.5 w-5 rounded-sm"
                  />
                  <span key={`item-${index}`} className=" flex font-normal not-italic text-xs text-gray-700">{entry.value}</span>
                </div>
              ))
            }
      </>
    );
  };
  const RenderLabel = (label) => {
    const {
      x, y, width, value, height,
    } = label;
    return (
      <text className="text-xs" x={x + width / 1.5} y={y + height / 2} fill="white" textAnchor="end" dy={4}>
        {`${value >= 10 ? `${Math.round(value)}%` : ''}`}
      </text>
    );
  };

  const renderTooltip = (props) => {
    const { payload } = props;
    return (
      <div className="border border-solid bg-white p-1 opacity-100">
        <div className="grid">
          {payload.map((entry) => (
            <div style={{ display: 'flex', color: entry.color }}>
              <Text mr="5px">{entry.name}</Text>
              :
              <Text ml="5px">{`${entry.value.toFixed(2)}%`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="h-[5rem]">
      <div>
        <span className={`${Styles.BalanceSheetTitle} flex items-baseline`}>
          {type}
        </span>
        <div>
          <ResponsiveContainer width="99%" aspect={1.2}>
            <BarChart
              layout="vertical"
              width={500}
              height={200}
              data={finalData}
              margin={{
                top: 30,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis type="number" hide axisLine={false} domain={['min', 'max']} />
              <YAxis hide axisLine={false} />
              <Tooltip
                cursor={{
                  fill: 'none', stroke: 'none',
                }}
                content={renderTooltip}
                position={{ y: -50 }}
              />
              <Legend
                content={renderLegend}
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  margin: '0.313rem 0rem',
                  top: '0.125rem',
                  paddingBottom: '0px',
                  display: 'flex',
                  gap: 12,
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
                iconType="square"
                iconSize={12}
              />
              {finalData?.map((item) => Object.keys(item).map((value, index) => (
                <Bar dataKey={value} stackId="stack" fill={type === 'Asset Distribution' ? assetColor[index] : liabilitiesColor[index]}>
                  <LabelList dataKey={value} position="center" content={RenderLabel} />
                </Bar>
              )))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
