import actions from '../actions';

export const initialState = {
  counter: 5,
};

const demoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case actions.SET_DECREMENT:
      return { ...state, counter: state.counter - 1 };
    default:
      return state;
  }
};

export default demoReducer;
