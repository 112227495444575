/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import useSorting from '../../../../components/CommonFunction/useSorting';
import {
  assetPrefix, displayDateInUtc, loadingStates, renderNumber,
} from '../../../../utilities/general';
import styles from './BalanceSheet.module.css';
import OverviewContainer from '../../CommonDetailsComponent/OverviewContainer';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { balanceSheetExchangeApi } from '../../../../utilities/apis/companyData';
import ExchangeLoading from '../../../../components/LoadingPlaceholder/ExchangeLoading';

export default function ExchangeTab() {
  const [exchangeData, setExchangeData] = useState();
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);

  const fetchBalanceSheetCurrencyData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(balanceSheetExchangeApi());

    if (resp?.success && resp?.data) {
      setExchangeData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet exchange data',
        title: 'Error while fetching balance-sheet exchange Data',
      });
    }
  };
  useEffect(() => {
    fetchBalanceSheetCurrencyData();
  }, []);
  const { items, requestSort, sortConfig } = useSorting(exchangeData?.allAssetsList, 'assetName');
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <div className="border border-[#CBD3E0] rounded-xl mx-8 my-8 dark:bg-inherit dark:border-neutral-800">
      <div className="mt-0 mx-6">
        <div className="flex w-full gap-3 pt-3 pb-12">
          <div className="w-full"><OverviewContainer type="Liquid" iconName="liquid" totalValue={exchangeData?.liquid?.value} changeInPercentage={exchangeData?.liquid?.changeInPercentage} dataLoading={dataLoading} /></div>
          <div className="w-full"><OverviewContainer type="Staked" iconName="stacked" totalValue={exchangeData?.staked?.value} changeInPercentage={exchangeData?.staked?.changeInPercentage} dataLoading={dataLoading} /></div>
        </div>
        <div className={`${styles.ExchangeListingContainer} dark:border-neutral-400`}>
          <div className="grid gap-3 p-2">
            <div className={`grid items-start transition-transform cursor-pointer pt-3 ${styles.ExchangeHeadingGrid}`}>
              <div className={styles.text}>
                <span
                  onClick={() => requestSort('location')}
                  className={getClassNamesFor('location')}
                >
                  Location
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('assetName')}
                  className={getClassNamesFor('assetName')}
                >
                  Currency
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('quantity')}
                  className={getClassNamesFor('quantity')}
                >
                  Quantity
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('value')}
                  className={getClassNamesFor('value')}
                >
                  Value
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('proportion')}
                  className={getClassNamesFor('proportion')}
                >
                  Proportion
                </span>
              </div>

              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('firstUpdate')}
                  className={getClassNamesFor('firstUpdate')}
                >
                  First Update
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('lastUpdate')}
                  className={getClassNamesFor('lastUpdate')}
                >
                  Last Update
                </span>
              </div>

            </div>
            <div className="grid text-sm font-normal text-[#898989] ml-[40.5%]">
              <div>USD</div>
            </div>
          </div>
          {dataLoading === loadingStates.LOADING && <ExchangeLoading />}

          { dataLoading === loadingStates.FINISHED && items.map((value, index) => (
            <div className={`${styles.rowContainer} ${index % 2 === 0 ? 'bg-slate-50' : 'bg-white'} dark:bg-inherit dark:border-neutral-600`}>
              <div className={`grid items-center transition-transform px-4 ${styles.ExchangeRowGrid} text-[#3A3A3A] dark:text-inherit p-2 p-0`}>
                <div className={`${styles.ExchangeRowText} !font-medium`}>
                  {value.location}
                </div>
                <div className={`${styles.ExchangeRowText} !font-medium flex items-center gap-2 capitalization`}>
                  <span>
                    <img
                      src={value?.assetIconLink || `${assetPrefix}/images/CrptoIcon.svg`}
                      alt="Bitcoin"
                      className="max-w-fit w-8 h-8 relative"
                    />
                  </span>
                  <span>
                    {value.assetName}
                  </span>
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(value.quantity)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(value.value, true)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(value.proportion * 100)}
                  %
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {displayDateInUtc(value.firstUpdate)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {displayDateInUtc(value.lastUpdate)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
