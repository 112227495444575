/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import styles from '../CompanyDataV2.module.css';
import { assetPrefix, loadingStates, renderNumber } from '../../../../utilities/general';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { V2balanceSheetAnalyticsApi, balanceSheetFinancialApi } from '../../../../utilities/apis/companyData';
import SingleLineCharts from './SingleLineBarCharts';
import InvestmentLineCharts from './InvestmentLineChart';
import LineCharts from '../LineChart';
import { InvestmentDummy } from './dummy';
import BarCharts from '../BarChart';
import StatsCard from '../StatsCard';
import InvestmentListing from './InvestmentListing';

export default function BalanceSheet() {
  const [balanceSheetDataConfigs, setBalanceSheetDataConfigs] = useState({
    dataLoading: loadingStates.NO_ACTIVE_REQUEST,
    balanceSheetData: null,
  });
  const changeInPercentage = 2.45;

  const BalanceSheetAnalyticsData = async () => {
    setBalanceSheetDataConfigs({
      ...balanceSheetDataConfigs,
      dataLoading: loadingStates.LOADING,
    });
    const resp = await apiWrapWithErrorWithData(V2balanceSheetAnalyticsApi());

    if (resp?.success && resp?.data) {
      setBalanceSheetDataConfigs((prevValue) => ({
        ...prevValue,
        dataLoading: loadingStates.FINISHED,
        balanceSheetData: resp.data,
      }));
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet financial data',
        title: 'Error while fetching balance-sheet financial Data',
      });
    }
  };
  useEffect(() => {
    BalanceSheetAnalyticsData();
  }, []);
  const totalProgress = 49;
  const statsData = [
    {
      label: 'Book Value - Hot',
      value: 34,
      sections: [
        { value: (34 / totalProgress) * 100, color: '#33B1FF' },
      ],
    },
    {
      label: 'Hot Value - Delta',
      value: 16,
      sections: [{ value: (16 / totalProgress) * 100, color: 'green' }],
    },
    {
      label: 'Book Value - Cold',
      value: 18,
      sections: [{ value: (18 / totalProgress) * 100, color: '#33B1FF' }],
    },
    {
      label: 'Cold Value - Delta',
      value: 15,
      sections: [{ value: (15 / totalProgress) * 100, color: '#33B1FF' }],
    }];
  return (
    <div>
      <div className="p-12">
        <div className={styles.balanceSheetDataLayout}>

          {/* First row */}
          <div className={styles.pnlCard}>
            <InvestmentLineCharts
              chartName="Investments"
              summury={0.46}
              changeInPercentage={2.45}
              days={90}
            />
          </div>
          <div className={styles.winRate}>
            <LineCharts
              chartName="Profit / Loss"
              loading="FINISHED"
              summury={1643}
              changeInPercentage={90}
              days={90}
            />
          </div>
          <div className={styles.valTotalAssets}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={`${styles.valueText} text-green-500`}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.totalAssets, true)}</span>
              <span className={`${styles.Text}`}>Total Assets</span>
            </div>
          </div>
          <div className={styles.valTotalLiabilities}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={`${styles.valueText} text-red-500`}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.totalLiabilities, true)}</span>
              <span className={`${styles.Text}`}>Total Liabilities</span>
            </div>
          </div>

          {/* 2nd row */}

          <div className={styles.pnlCard2}>
            <BarCharts
              data={InvestmentDummy}
              loading="FINISHED"
              chartName="Liquid"
              summury={8.51}
              days="Last 6 Months"
              color={['#0093EF', '#E9EDF7']}
              // changeInPercentage={snapshotData?.dailyUnAllocatedValueSummary.changeInPercentage}
            />
          </div>
          <div className={styles.roiCard}>
            <BarCharts
              data={InvestmentDummy}
              loading="FINISHED"
              chartName="Staked"
              summury={8.51}
              days="Last 6 Months"
              color={['#F9A61A', '#F8994238']}
            />
          </div>
          <div className={styles.valTotalFund}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={styles.valueText}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.totalInvestments, true)}</span>
              <span className={`${styles.Text}`}>Total Investments</span>
            </div>
          </div>
          <div className={styles.valTotalCurrVal}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={styles.valueText}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.totalPnL, true)}</span>
              <span className={`${styles.Text}`}>Total Profit / Loss</span>
            </div>
          </div>

          {/* 3rd row */}

          <div className={`${styles.hotColdWallet} pt-2`}>
            <InvestmentListing data={balanceSheetDataConfigs?.balanceSheetData?.stakingData} />
          </div>
          {/* <div className={`${styles.statsCard} pt-2`}>
            <StatsCard title="Book Values" data={statsData} />
          </div> */}
          <div className={styles.profitsCard}>
            <div>
              <div className="grid 2xl:gap-0 md:gap-4 pt-0">
                <SingleLineCharts data={balanceSheetDataConfigs?.balanceSheetData?.assetDistributionPercentage} type="Asset Distribution" />
                <SingleLineCharts data={balanceSheetDataConfigs?.balanceSheetData?.liabilitiesPercentage} type="Liabilities Distribution" />
              </div>
            </div>
          </div>
          <div className={styles.bookValueHot}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <div className="flex gap-2">
                <div className={styles.valueText}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.bookValues?.bookValueHot, true)}</div>
                <div className="flex gap-1.5 items-center">
                  <span>
                    {(changeInPercentage > 0)
                      ? (
                        <img
                          src={`${assetPrefix}/images/GreenArrow.svg`}
                          alt="High"
                          className="max-w-fit w-3 h-3"
                        />
                      )
                      : (
                        <img
                          src={`${assetPrefix}/images/RedArrow.svg`}
                          alt="Low"
                          className="max-w-fit w-3 h-3"
                        />
                      )}
                  </span>
                  <span className={`${styles.changeInPercentage} ${changeInPercentage > 0 ? 'text-green-500' : 'text-red-500'}`}>{`${changeInPercentage ? renderNumber(changeInPercentage) : '+2.45'}%`}</span>
                </div>
              </div>
              <span className={`${styles.Text}`}>Book Value - Hot</span>
            </div>
          </div>
          <div className={styles.bookValueCold}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <div className="flex gap-2">
                <div className={styles.valueText}>{renderNumber(balanceSheetDataConfigs.balanceSheetData?.bookValues?.bookValueCold, true)}</div>
                <div className="flex gap-1.5 items-center">
                  <span>
                    {(changeInPercentage > 0)
                      ? (
                        <img
                          src={`${assetPrefix}/images/GreenArrow.svg`}
                          alt="High"
                          className="max-w-fit w-3 h-3"
                        />
                      )
                      : (
                        <img
                          src={`${assetPrefix}/images/RedArrow.svg`}
                          alt="Low"
                          className="max-w-fit w-3 h-3"
                        />
                      )}
                  </span>
                  <span className={`${styles.changeInPercentage} ${changeInPercentage > 0 ? 'text-green-500' : 'text-red-500'}`}>{`${changeInPercentage ? renderNumber(changeInPercentage) : '+2.45'}%`}</span>
                </div>
              </div>
              <span className={`${styles.Text}`}>Book Value - Cold</span>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}
