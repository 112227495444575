import React from 'react';
import CompanyBooks from './CompanyBooks';
import BalanceSheet from './BalanceSheet';
import BonusPool from './BonusPool';

export default function CompanyVisits(props) {
  const { data, dataLoading } = props;
  return (
    <div>
      <div className="p-11 flex w-full h-full justify-between">
        <div className="flex justify-around w-full">
          <CompanyBooks data={data && data.companyBook} dataLoading={dataLoading} />
          <BalanceSheet data={data && data.companyBalanceSheet} dataLoading={dataLoading} />
          <BonusPool data={data && data.bonusPool} dataLoading={dataLoading} />
        </div>
      </div>
    </div>
  );
}
