/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

export const getLiabilitiesApi = (traderId) => axios.get(`${constants.tradersApiHost}/liability/${traderId}/liability-data`, {
  headers: {
    ...headersProvider(),
  },
});

export const postLiabilitiesApi = (traderId, data) => axios.post(`${constants.tradersApiHost}/liability/${traderId}/update_liablity_data`,
  {
    ...data,
  },
  {
    noTrailingSlash: true,
    headers: headersProvider(),
  });
