import { combineReducers } from 'redux';
import demoReducer from './DemoReducer';
import loggedInStatus from './loggedInStatus';

const rootReducer = combineReducers({
  demoReducer,
  loggedInStatus,
});

export default rootReducer;
