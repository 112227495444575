import React from 'react';
import {
  arrayOf, func, shape, string,
} from 'prop-types';

import { buttonize } from '../../utilities/general';
import styles from './TabBar.module.css';

function TabBar(props) {
  const { options, tabName, handleChange } = props;
  return (
    <>
      <div className={`${styles.tabsList} flex mb-0 list-none flex-wrap flex-row`}>
        {
          options && options.length > 0 && options.map((option) => (
            <div
              {...buttonize(() => handleChange(option.value))}
              key={option.value}
              className={
                tabName === option.value
                  ? `${styles.tabItem} ${styles.tabActive}`
                  : styles.tabItem
              }
            >
              <span className="focus:outline-none">{option.label}</span>
            </div>
          ))
        }
      </div>
    </>

  );
}

TabBar.propTypes = {
  options: arrayOf(shape({
    value: string,
    label: string,
  })).isRequired,
  tabName: string.isRequired,
  handleChange: func.isRequired,
};

export default TabBar;
