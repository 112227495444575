/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { assetPrefix, loadingStates, renderNumber } from '../../../../utilities/general';
import OverviewContainer from '../../CommonDetailsComponent/OverviewContainer';
import useSorting from '../../../../components/CommonFunction/useSorting';
import styles from './BalanceSheet.module.css';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { balanceSheetCurrencyApi } from '../../../../utilities/apis/companyData';
import CurrencyLoading from '../../../../components/LoadingPlaceholder/CurrencyLoading';

export default function CurrencyTab() {
  const [currencyData, setCurrencyData] = useState();
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);

  const fetchBalanceSheetCurrencyData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(balanceSheetCurrencyApi());
    if (resp?.success && resp?.data) {
      setCurrencyData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet currency data',
        title: 'Error while fetching balance-sheet currency Data',
      });
    }
  };
  useEffect(() => {
    fetchBalanceSheetCurrencyData();
  }, []);
  const { items, requestSort, sortConfig } = useSorting(currencyData?.allAssetsList, 'quantity');
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <div className="border border-[#CBD3E0] dark:border-neutral-800 rounded-xl mx-8 my-8">
      <div className="mt-0 mx-6">
        <div className="flex w-full gap-3 pt-3 pb-12">
          <div className="w-full"><OverviewContainer type="Hot Value" iconName="chart-pie" totalValue={currencyData?.hotValue?.value} changeInPercentage={currencyData?.hotValue?.changeInPercentage} dataLoading={dataLoading} /></div>
          <div className="w-full"><OverviewContainer type="Cold Value" iconName="coldValue" totalValue={currencyData?.coldValue?.value} changeInPercentage={currencyData?.coldValue?.changeInPercentage} dataLoading={dataLoading} /></div>
        </div>
        <div className={`${styles.ExchangeListingContainer} dark:border-neutral-400`}>
          <div className="grid gap-3 p-2">
            <div className={`grid items-start transition-transform cursor-pointer text-[#3A3A3A] dark:text-inherit pt-3 ${styles.CurrencyHeadingGrid}`}>
              <div className={styles.text}>
                <span
                  onClick={() => requestSort('assetName')}
                  className={getClassNamesFor('assetName')}
                >
                  Asset
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('quantity')}
                  className={getClassNamesFor('quantity')}
                >
                  Quantity
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('value')}
                  className={getClassNamesFor('value')}
                >
                  Value
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('hotPercentage')}
                  className={getClassNamesFor('hotPercentage')}
                >
                  Hot
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('hotValue')}
                  className={getClassNamesFor('hotValue')}
                >
                  Hot Value
                </span>
              </div>

              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('coldPercentage')}
                  className={getClassNamesFor('coldPercentage')}
                >
                  Cold
                </span>
              </div>
              <div className={`${styles.text} gap-3`}>
                <span
                  onClick={() => requestSort('coldValue')}
                  className={getClassNamesFor('coldValue')}
                >
                  Cold Value
                </span>
              </div>

            </div>
            <div className={`grid text-sm font-normal text-[#898989] ml-[29.8%] ${styles.CurrencySubHeadingGrid}`}>
              <div>Value</div>
              <div>USD</div>
            </div>
          </div>
          {dataLoading === loadingStates.LOADING && <CurrencyLoading />}

          {dataLoading === loadingStates.FINISHED && items.map((item, index) => (
            <div className={`${styles.rowContainer} ${index % 2 === 0 ? 'bg-slate-50' : 'bg-white'} dark:bg-inherit dark:border-neutral-600`}>
              <div className={`grid items-center transition-transform px-4 ${styles.CurrencyRowGrid} text-[#3A3A3A] dark:text-inherit p-2`}>
                <div className={`${styles.ExchangeRowText} items-center !font-medium flex gap-2 capitalization`}>
                  <img
                    src={item?.assetIconLink || `${assetPrefix}/images/CrptoIcon.svg`}
                    alt="Bitcoin"
                    className="max-w-fit w-8 h-8 relative"
                  />
                  {item?.assetName ? item?.assetName : '-'}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(item.quantity)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(item.value, true)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {`${renderNumber(item.hotPercentage * 100)}%`}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(item.hotValue, true)}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {`${renderNumber(item.coldPercentage * 100)}%`}
                </div>
                <div className={`${styles.ExchangeRowText}`}>
                  {renderNumber(item.coldValue, true)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
