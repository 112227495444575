/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { FoldDown, FoldUp } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import { LoadingOverlay } from '@mantine/core';
import CustomInput from './CustumInput';
import Layout from '../../pages/Layout/Layout';
import { getLiabilitiesApi, postLiabilitiesApi } from '../../utilities/apis/liabilities';
import { apiWrapWithErrorWithData } from '../../utilities/apis/apiHelpers';
import { loadingStates } from '../../utilities/general';
import FlashLoader from '../CommonFunction/FlashLoader';
import Styles from './Form.module.css';
// const liabilities = {
//   capital: [
//     {
//       type: 'liability',
//       subType: 'capital',
//       addedOn: '2019-01-01T00:00:00Z',
//       lastUpdatedOn: '2019-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u399393-0839',
//       value: {
//         capital: '1000000',
//         date: '2018-01-01T00:00:00Z',
//       },
//     },
//     // Add more capital items here if needed
//   ],
//   loan: [
//     {
//       type: 'liability',
//       subType: 'loan',
//       addedOn: '2018-01-02T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u399393-1839',
//       value: {
//         loanAmount: '500000',
//         date: '2018-01-01T00:00:00Z',
//         interestRate: '10',
//         tenureInMonths: '12',
//       },
//     },
//     {
//       type: 'liability',
//       subType: 'loan',
//       addedOn: '2018-01-02T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u399393-1839',
//       value: {
//         loanAmount: '500000',
//         date: '2018-01-01T00:00:00Z',
//         interestRate: '10',
//         tenureInMonths: '12',
//       },
//     },
//     // Add more loan items here if needed
//   ],
//   overdraft: [
//     {
//       type: 'liability',
//       subType: 'overdraft',
//       addedOn: '2018-01-04T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u399393-2829',
//       value: {
//         amount: '100000',
//         date: '2018-01-01T00:00:00Z',
//         associatedFee: '1000',
//         rateOfInterest: '12',
//         repaymentTenureInMonths: '12',
//       },
//     },
//     // Add more overdraft items here if needed
//   ],
//   accountPayables: [
//     {
//       type: 'liability',
//       subType: 'accountPayables',
//       addedOn: '2018-01-05T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u399393-3879',
//       value: {
//         amount: '100000',
//         vendor: 'vendor1',
//         dueDate: '2018-01-01T00:00:00Z',
//       },
//     },
//     // Add more accountPayables items here if needed
//   ],
//   sundryCreditors: [
//     {
//       type: 'liability',
//       subType: 'sundryCreditors',
//       addedOn: '2018-01-06T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-4829',
//       value: {
//         amount: '100000',
//         creditor: 'creditor1',
//         date: '2018-01-01T00:00:00Z',
//         rateOfInterest: '12',
//         repaymentTenureInMonths: '12',
//       },
//     },
//     // Add more sundryCreditors items here if needed
//   ],
//   mortgage: [
//     {
//       type: 'liability',
//       subType: 'mortgage',
//       addedOn: '2018-01-07T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-5429',
//       value: {
//         principalAmount: '1000000',
//         lender: 'lender1',
//         rateOfInterest: '12',
//         maturityDate: '2018-01-01T00:00:00Z',
//       },
//     },
//     // Add more mortgage items here if needed
//   ],
//   deferredRevenue: [
//     {
//       type: 'liability',
//       subType: 'deferredRevenue',
//       addedOn: '2018-01-08T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-6429',
//       value: {
//         amount: '100000',
//         source: 'source1',
//         expirationDate: '2018-01-01T00:00:00Z',
//       },
//     },
//     // Add more deferredRevenue items here if needed
//   ],
//   investment: [
//     {
//       type: 'asset',
//       subType: 'investment',
//       addedOn: '2018-01-09T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-7429',
//       value: {
//         type: 'mutualFund',
//         amount: '100000',
//         date: '2018-01-01T00:00:00Z',
//         currentMarketValue: '110000',
//       },
//     },
//     // Add more investment items here if needed
//   ],
//   sundryDebtors: [
//     {
//       type: 'asset',
//       subType: 'sundryDebtors',
//       addedOn: '2018-01-10T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-8429',
//       value: {
//         amountOwed: '100000',
//         debitor: 'debtor1',
//         dueDate: '2018-01-01T00:00:00Z',
//         paymentTenureInMonths: '12',
//       },
//     },
//     // Add more sundryDebtors items here if needed
//   ],
//   cashAtBank: [
//     {
//       type: 'asset',
//       subType: 'cashAtBank',
//       addedOn: '2018-01-11T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-9429',
//       value: {
//         bankName: 'bank1',
//         accountNumber: '1234567890',
//         currentBalance: '100000',
//       },
//     },
//     // Add more cashAtBank items here if needed
//   ],
//   cashInHand: [
//     {
//       type: 'asset',
//       subType: 'cashInHand',
//       addedOn: '2018-01-12T00:00:00Z',
//       lastUpdatedOn: '2018-01-01T00:00:00Z',
//       uuid: 'a3933-3939-u599393-1029',
//       value: {
//         amount: '100000',
//         location: 'source1',
//         remark: 'ritesh',
//       },
//     },
//     // Add more cashInHand items here if needed
//   ],
//   // Add more asset types here if needed
// };

// console.log(liabilities);

export default function Form() {
  const [openLiabilities, setOpenLiabilities] = useState(true);
  const [openAssets, setOpenAssets] = useState(true);
  const [liabilityData, setLiabilityData] = useState(null);
  const [postData, setPostData] = useState(null);
  const [shouldCallApi, setShouldCallApi] = useState(false);
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);

  const liabilityDataApi = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(getLiabilitiesApi('6b94b87c-9a78-4271-baeb-0faf5f77a34d'));
    if (resp.success) {
      setLiabilityData(resp.data.new_liability_data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load form data',
        title: 'Error while loading form data',
      });
    }
  };
  const postLiabilityDataApi = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(postLiabilitiesApi('6b94b87c-9a78-4271-baeb-0faf5f77a34d', postData));
    if (resp.success) {
      setShouldCallApi(false);
      liabilityDataApi();
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t update form data',
        title: 'Error while updating form data',
      });
    }
  };
  useEffect(() => {
    if (shouldCallApi && postData !== null) {
      postLiabilityDataApi();
    }
  }, [postData]);

  useEffect(() => {
    liabilityDataApi();
  }, []);

  return (
    <Layout>
      <div className="relative min-h-screen">
        <LoadingOverlay className={Styles.load} loader={<FlashLoader />} overlayBlur={5} visible={dataLoading === loadingStates.LOADING} />
        <div className={Styles.mainBoxLayout}>
          {/* {dataLoading === loadingStates.LOADING
        && (
          <div className="min-w-min h-screen p-12">
            <div className="items-center flex justify-center bg-white w-full h-[40rem]"><FlashLoader /></div>
          </div>
        )} */}
          {/* {dataLoading === loadingStates.FINISHED
        && ( */}
          <div className="grid justify-center px-5 w-full gap-20 py-5 pt-20">
            <div className="w-full min-w-[75rem]">
              <div onClick={() => { setOpenLiabilities(!openLiabilities); }} className="flex gap-3 cursor-pointer w-fit">
                <div className={`${openLiabilities ? 'bg-[#E65353]' : 'bg-[#47CC79]'} h-11 w-11 rounded flex items-center justify-center`}>
                  {openLiabilities ? (
                    <FoldDown
                      size={24}
                      strokeWidth={1.5}
                      color="white"
                    />
                  ) : (
                    <FoldUp
                      size={24}
                      strokeWidth={1.5}
                      color="white"
                    />
                  )}

                </div>
                <div className="text-[2rem] not-italic font-bold">
                  Liabilities
                </div>
              </div>
            </div>
            {openLiabilities && liabilityData && Object.keys(liabilityData)?.map((item, index) => (

              index < 7
              && (
                <>
                  <div>
                    <CustomInput
                      label={item}
                      totalDatas={liabilityData}
                      setPostData={setPostData}
                      setShouldCallApi={setShouldCallApi}
                      liabilityData={liabilityData[item]}
                    />
                  </div>
                </>
              )
            ))}

            <div className="w-full min-w-[75rem]">
              <div onClick={() => { setOpenAssets(!openAssets); }} className="flex gap-3 cursor-pointer w-fit">
                <div className={`${openAssets ? 'bg-[#E65353]' : 'bg-[#47CC79]'} h-11 w-11 rounded flex items-center justify-center`}>
                  {openAssets ? (
                    <FoldDown
                      size={24}
                      strokeWidth={1.5}
                      color="white"
                    />
                  ) : (
                    <FoldUp
                      size={24}
                      strokeWidth={1.5}
                      color="white"
                    />
                  )}

                </div>
                <div className="text-[2rem] not-italic font-bold">
                  Assets
                </div>
              </div>
            </div>
            {openAssets && liabilityData && Object.keys(liabilityData)?.map((item, index) => (

              index >= 7
              && (
                <>
                  <div>
                    <CustomInput
                      label={item}
                      totalDatas={liabilityData}
                      setPostData={setPostData}
                      setShouldCallApi={setShouldCallApi}
                      liabilityData={liabilityData[item]}
                    />
                  </div>
                </>
              )
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
    </Layout>
  );
}
