import React from 'react';

export default function FlashLoader() {
  return (
    <div className="flashLoading flex justify-center items-center h-full">
      <span />
      <span />
      <span />
    </div>
  );
}
