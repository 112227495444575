import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwind-preflight.css';
import { Provider } from 'react-redux';
// import { MantineProvider } from '@mantine/core';
// import { NotificationsProvider } from '@mantine/notifications';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import MainWrapper from './pages/MainWrapper';

// eslint-disable-next-line no-extend-native
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

  const str = this.toString();
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0;
  } if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return str.split('-')[1] || 0;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    {/* <MantineProvider> */}
    {/* <NotificationsProvider> */}
    <MainWrapper>
      <App />
    </MainWrapper>
    {/* </NotificationsProvider> */}
    {/* </MantineProvider> */}
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
