import React from 'react';
import styles from '../ListingCommonStyle.module.css';
import HeaderCell from '../HeaderCell';

const TraderListingHeader = () => (
  <>
    <div className={`${styles.grid6cols} ${styles.rowContainer} ${styles.headerRow} !items-baseline`}>
      <HeaderCell
        classes="!justify-start"
        text="Traders"
      />
      <HeaderCell
        text="Total Book Value"
      />
      <HeaderCell
        text="Total Investments"
      />
      <HeaderCell
        text="Unallocated Value"
      />
      <HeaderCell
        text="Current Book Value"
      />
      <HeaderCell
        text="Value of Open Positions"
      />
      <HeaderCell
        classes=""
        text="Running Profit / loss"
        subHeading="From Open Positions"
      />
      <div className={styles.starColumn} />
    </div>
    {/* <div className={`${styles.rowContainer}
     ${styles.TraderHeaderSubGrid} ${styles.headerRow} pt-0 mb-0`}>
      <span>
        Account ID
      </span>
      <span>
        Total
      </span>
    </div> */}
  </>
);

export default TraderListingHeader;
