/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { Loader, Text } from '@mantine/core';
import Styles from './BalanceSheet.module.css';
import { loadingStates } from '../../../../utilities/general';

export default function SingleLineBarChart({
  type, data, tableChartData, tableChart, dataLoading,
}) {
  const color = ['#003A6D', '#026DB0', '#30BEF5'];
  const [finalData, setFinalData] = useState();
  // const arr = [{}];

  // data?.forEach((items) => {
  //   arr[0][items.share] = items.percentage * 100;
  // });
  useEffect(() => {
    if (data) {
      const newArray = [{}];
      data?.forEach((items) => {
        newArray[0][items.share] = items.percentage * 100;
      });
      setFinalData(newArray);
    }
  }, [data]);
  // arr.push({});

  // useEffect(() => {
  //   setFinalData(arr);
  // }, [data]);

  function calculateAverageValues(val) {
    const result = [{}];
    const keys = val && Object?.keys(val[0]);
    const values = val && Object.values(val[0]);
    const summ = values?.reduce((total, item) => total + item, 0);

    if (values?.length !== 0) {
      values?.forEach((num, index) => {
        const avg = (num / summ) * 100;
        result[0][keys[index]] = avg;
      });
    }
    result.push({});
    return result;
  }

  const averageValues = calculateAverageValues(tableChartData);
  const barData = tableChart ? averageValues : finalData;
  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
          payload.map((entry, index) => (
            <div className="flex items-center gap-1.5">
              <span
                style={{
                  backgroundColor: entry.color,
                }}
                className="h-3.5 w-3.5 rounded-[3px]"
              />
              <span key={`item-${index}`} className=" flex font-normal not-italic text-sm text-gray-700 dark:text-slate-400">{entry.value}</span>
            </div>
          ))
        }
      </>
    );
  };
  const RenderLabel = (label) => {
    const {
      x, y, width, value, height,
    } = label;
    return (
      <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle" dy={5}>
        {`${value >= 10 ? `${Math.round(value)}%` : ''}`}
      </text>
    );
  };
  const renderTooltip = (props) => {
    const { payload } = props;
    return (
      <div className="border border-solid bg-white p-2 opacity-80">
        <div className="grid">
          {payload?.map((entry) => (
            <div style={{ display: 'flex', color: entry.color }}>
              <Text mr="5px">{entry.name}</Text>
              :
              <Text ml="5px">{`${entry.value.toFixed(2)}%`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="border border-slate-100 dark:border-neutral-600 h-[12rem] w-full">
      <div className="py-6 px-7">
        <span className={`${Styles.BalanceSheetTitle} text-[#333333] flex items-baseline dark:text-inherit`}>
          {type}
        </span>
        {dataLoading === loadingStates.LOADING
        && <div className=" flex justify-center w-full h-[8rem] items-center"><Loader variant="dots" /></div>}
        {dataLoading === loadingStates.FINISHED
        && (
        <div>
          <ResponsiveContainer width="99%" aspect={1.2}>
            <BarChart
              layout="vertical"
              width={700}
              barSize={42}
              height={200}
              data={barData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 100,
              }}
            >
              <XAxis type="number" hide axisLine={false} domain={['min', 'max']} />
              <YAxis hide axisLine={false} type="category" />
              <Tooltip
                cursor={{
                  fill: 'none', stroke: 'none',
                }}
                content={renderTooltip}
                // position={{ y: 0 }}
              />
              <Legend
                content={renderLegend}
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  margin: '0.313rem 0rem',
                  top: tableChart ? '10px' : '5px',
                  paddingBottom: '0px',
                  display: 'flex',
                  gap: 12,
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
                iconType="square"
                iconSize={12}
              />
              {barData?.map((item) => Object.keys(item).map((value, index) => (
                <Bar dataKey={value} stackId="stack" fill={color[index]}>
                  <LabelList dataKey={value} position="center" content={RenderLabel} />
                </Bar>
              )))}

            </BarChart>
          </ResponsiveContainer>
        </div>
        )}
      </div>
    </div>
  );
}
