/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Select, Table,
  useMantineColorScheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import { setUserRoleApi } from '../../../utilities/apis/setting';
import { apiWrapWithErrorWithData } from '../../../utilities/apis/apiHelpers';
import { loadingStates } from '../../../utilities/general';

const AccessControl = ({ accessData }) => {
  const { colorScheme } = useMantineColorScheme();
  const [selectedValues, setSelectedValues] = useState([]);
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const accessLevel = ['Admin', 'Trader'];
  // const handleSelectChange = (user, value) => {
  //   setSelectedValues((prevValues) => ({
  //     ...prevValues,
  //     email: user,
  //     role: value,
  //   }));
  // };
  const handleSelectChange = (email, role) => {
    setSelectedValues((prevValues) => {
      const userIndex = prevValues.findIndex((item) => item.email === email);

      if (userIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[userIndex] = { ...updatedValues[userIndex], role };
        return updatedValues;
      }

      return [...prevValues, { email, role }];
    });
  };

  const setUserRoleData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(setUserRoleApi(selectedValues));
    if (resp?.success) {
      setDataLoading(loadingStates.FINISHED);
      showNotification({
        color: 'green',
        message: 'Successfully Updated User-Role.',
      });
    } else {
      setDataLoading(loadingStates.FAILED);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load set User-Role.',
      });
    }
  };

  useEffect(() => {
    accessData?.forEach((val) => {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [val.email]: val.userType,
      }));
    });
  }, []);

  const SelectButton = (user, userType, accessLevels) => (
    <Select
      value={selectedValues[user]}
      defaultValue={userType}
      onChange={(value) => handleSelectChange(user, value)}
      placeholder="Pick one"
      data={accessLevels.map((option) => ({
        value: option,
        label: option,
      }))}
      rightSection={<ChevronDown size={14} />}
      rightSectionWidth={30}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      variant="unstyled"
      dropdownPosition="bottom"
    />
  );

  const Rows = accessData && accessData?.map((data) => (
    <tr key={data.email} className="bg-slate-50">
      <td className="w-1/5">{data?.name}</td>
      <td className="w-1/5">{data?.email}</td>
      <td className="w-1/5">{SelectButton(data.email, data?.userType, accessLevel)}</td>
    </tr>
  ));

  return (
    <div className="w-[80%] p-10">
      <Table
        withBorder
        withColumnBorders
        horizontalSpacing="xs"
        sx={{
          '& thead tr th': {
            borderColor: colorScheme === 'dark' && '#5c5a5a',
            color: 'inherit',
          },
          '& tbody tr td': {
            borderColor: colorScheme === 'dark' && '#5c5a5a',
          },
        }}
      >
        <thead>
          <tr className="font-semibold">
            <th>User</th>
            <th>E-mail</th>
            <th>Access Level</th>
          </tr>
        </thead>
        <tbody>{Rows}</tbody>
      </Table>
      <div className="justify-end flex mt-4">
        <Button className="h-10 text-base not-italic font-normal bg-[#33B1FF]" radius="sm" loaderPosition="center" loading={dataLoading === loadingStates.LOADING} onClick={setUserRoleData}>Save</Button>
      </div>
    </div>
  );
};

export default AccessControl;
