/* eslint-disable import/prefer-default-export */
export const InvestmentDummy = [
  {
    date: '2023-11-5',
    value: 2000,
  },
  {
    date: '2023-11-30',
    value: 8592.77,
  },
  {
    date: '2023-12-15',
    value: 4000.49,
  },
  {
    date: '2023-12-31',
    value: 7438.6,
  },
  {
    date: '2024-01-15',
    value: 6002.49,
  },
  {
    date: '2024-01-30',
    value: 4072.49,
  },
];

export const PieChartDummy = [
  {
    name: 'Back to Company',
    value: 400,
  },
  {
    name: 'Back to Company',
    value: 300,
  }, {
    name: 'Back to Company',
    value: 500,
  }, {
    name: 'Back to Company',
    value: 480,
  },
];
