const routes = {
  TRADERS_DATA_LISTING: '/traders-list',
  BALANCE_SHEET: '/balance-sheet',
  TRADERS_DATA_DETAIL: '/traders-details/:userId',
  BALANCE_SHEET_DETAIL: '/balance-sheet-details',
  COMPANY_DATA: '/company-data',
  COMPANY_DATA_V2: '/company-data-v2',
  COMPANY_BOOK_DETAIL: '/company-book-details',
  BONUS_POOL_DETAIL: '/bonusPool-data-details',
  FORM: '/liabilities-assets-form',
  SETTINGS: '/settings',

};

export default routes;
