import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { BeatLoader } from 'react-spinners';
import { deleteCookie, getCookiesSession } from '../utilities/cookiesSession';
import actions from '../redux/actions';
import { userDetailsApi } from '../utilities/apis/adminLogin';
import { apiWrapWithErrorWithData } from '../utilities/apis/apiHelpers';
import FlashLoader from '../components/CommonFunction/FlashLoader';

const MainWrapper = ({ children }) => {
  const [cookieChecked, setCookieChecked] = useState(false);
  const dispatch = useDispatch();
  const updateStoreForLogin = (data) => dispatch({
    type: actions.SET_LOGGED_IN_STATUS,
    payload: {
      ...data,
      loggedIn: true,
    },
  });
  const checkUserLogin = async () => {
    // check cookies and get token
    if (!cookieChecked) {
      let existingCookieData = null;
      try {
        existingCookieData = JSON.parse(getCookiesSession('authToken'));
      } catch (e) {
        console.error('Failed to parse existing cookie', e);
      }
      if (existingCookieData?.token && existingCookieData?.userId) {
        const respUser = await apiWrapWithErrorWithData(userDetailsApi());
        if (respUser?.success && respUser?.userId) {
          updateStoreForLogin(existingCookieData);
        } else {
          deleteCookie('authToken');
        }
      }
      setCookieChecked(true);
    }
  };
  useEffect(() => { checkUserLogin(); }, [cookieChecked]);
  if (!cookieChecked) {
    return (
      <div className="w-screen h-screen flex flex-row justify-center items-center">
        {/* <BeatLoader color="blue" size={10} /> */}
        <FlashLoader />
      </div>
    );
  }
  return (children);
};

export default MainWrapper;
