/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Progress } from '@mantine/core';
import styles from './CompanyDataV2.module.css';
import { renderNumber } from '../../../utilities/general';

export default function StatsCard(props) {
  const {
    data,
    title,
  } = props;

  return (
    <div>
      <div className="px-6">
        <div className={`${styles.HeadingText} pt-3`}>{title}</div>
        <div className={`${styles.HeadingText} grid pt-6 gap-6`}>
          {data.map((stat, index) => (
            <div key={index}>
              <div className="flex flex-row justify-between item-start">
                <span className="!font-normal">{stat.label}</span>
                <span>{renderNumber(stat.value, title === 'Book Values' && true)}</span>
              </div>
              <Progress
                size="xs"
                sections={stat.sections}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
