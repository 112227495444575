/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import DistributionChart from './DistributionChart';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { balanceSheetDistributionApi } from '../../../../utilities/apis/companyData';
import { loadingStates } from '../../../../utilities/general';
import OverviewContainer from '../../CommonDetailsComponent/OverviewContainer';
import FlashLoader from '../../../../components/CommonFunction/FlashLoader';

export default function BalanceSheetTab() {
  const [distributionData, setDistributionData] = useState({});
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const fetchBalanceSheetDistributionData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(balanceSheetDistributionApi());

    if (resp?.success && resp?.data) {
      setDistributionData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet distribution data',
        title: 'Error while fetching balance-sheet distribution Data',
      });
    }
  };
  useEffect(() => {
    fetchBalanceSheetDistributionData();
  }, []);
  return (
    <div className="mx-8">
      <div className="flex gap-3 py-8 justify-center">
        {dataLoading === loadingStates.LOADING && <div className="h-screen"><FlashLoader /></div>}
        {dataLoading === loadingStates.FINISHED
              && (
              <>
                <div className="border border-[#CBD3E0] dark:border-neutral-800 rounded-xl p-6 pt-3 w-[50%]">
                  {/* <span className={`${Styles.BalanceSheetTitle} !text-2xl`}>Assets</span> */}
                  <div>
                    <OverviewContainer
                      type="Assets"
                      iconName="Setting"
                      totalValue={distributionData?.assets?.distribution?.totalValue}
                      changeInPercentage={distributionData?.assets?.distribution?.changeInPercentage}
                      dataLoading={dataLoading}
                    />

                  </div>
                  <div>
                    <DistributionChart
                      type="Asset Distribution"
                      data={distributionData}
                      keyName="assets"
                      dataLoading={dataLoading}
                    />

                  </div>
                </div>
                <div className="border border-[#CBD3E0] dark:border-neutral-800  rounded-xl p-6 pt-3 w-[50%]">
                  {/* <span className={`${Styles.BalanceSheetTitle} !text-2xl`}>Liabilities</span> */}
                  <div>
                    <OverviewContainer
                      type="Liabilities"
                      iconName="Liabilities"
                      totalValue={distributionData?.liabilities?.distribution?.totalValue}
                      changeInPercentage={distributionData?.liabilities?.distribution?.changeInPercentage}
                      dataLoading={dataLoading}
                    />

                  </div>
                  <div><DistributionChart type="Liabilities Distribution" data={distributionData} keyName="liabilities" dataLoading={dataLoading} /></div>
                </div>

              </>
              )}
      </div>
    </div>
  );
}
