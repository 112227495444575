/* eslint-disable consistent-return */
import React from 'react';
import { assetPrefix, loadingStates, renderNumber } from '../../../../utilities/general';
import styles from './BalanceSheet.module.css';
import FianancialLoading from '../../../../components/LoadingPlaceholder/FianancialLoading';
import useSorting from '../../../../components/CommonFunction/useSorting';
// import { ListingData } from './BalanceSheetDummy';

export default function FinancialListing({ data, dataLoading }) {
  const { items, requestSort, sortConfig } = useSorting(data, 'currentValue');
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <div className="w-[100%] px-1">
      <div className={`${styles.FinancialListingContainer} dark:border-neutral-400 mt-2`}>
        <div className={`${styles.FinancialListingHeader} pt-2 px-5`}>
          <div className={`grid items-start transition-transform cursor-pointer text-[#3A3A3A] dark:text-inherit ${styles.FinancialHeadingGrid}`}>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('assetName')}
                className={getClassNamesFor('assetName')}
              >
                Assets
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('quantity')}
                className={getClassNamesFor('quantity')}
              >
                Quantity
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('totalInvestment')}
                className={getClassNamesFor('totalInvestment')}
              >
                Total Investment
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('currentValue')}
                className={getClassNamesFor('currentValue')}
              >
                Current Value
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('pnl')}
                className={getClassNamesFor('pnl')}
              >
                Profit / Loss
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('roiPercentage')}
                className={getClassNamesFor('roiPercentage')}
              >
                ROI
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('direction')}
                className={getClassNamesFor('direction')}
              >
                Direction
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('24hDelta')}
                className={getClassNamesFor('24hDelta')}
              >
                24 Delta
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('7dDelta')}
                className={getClassNamesFor('7dDelta')}
              >
                7 Delta
              </span>
            </div>
            <div className={styles.ListingHeadingText}>
              <span
                onClick={() => requestSort('30dDelta')}
                className={getClassNamesFor('30dDelta')}
              >
                30 Delta
              </span>
            </div>
          </div>
        </div>
        <div className="pt-5">
          {dataLoading === loadingStates.LOADING && <FianancialLoading />}
          {dataLoading === loadingStates.FINISHED && items?.map((value, index) => (
            <div className={`${styles.rowContainer} ${index % 2 === 0 ? 'bg-slate-50' : 'bg-white'} dark:bg-inherit dark:border-neutral-600 h-[64px] pt-2`}>
              <div className={`grid items-start transition-transform px-4 text-[#000000] dark:text-inherit ${styles.rowGrid}`}>
                <div className="flex gap-2 font-medium not-italic text-base capitalization">
                  <span className="relative bottom-0">
                    <img
                      src={value?.assetIconLink || `${assetPrefix}/images/CrptoIcon.svg`}
                      alt="CoinBase"
                      className="max-w-fit w-8 h-8 relative bottom-1"
                    />
                  </span>
                  {value.assetName ? value?.assetName : '-'}
                </div>
                <div className="text-base not-italic font-normal">
                  {renderNumber(value?.quantity)}
                </div>
                <div className="text-base not-italic font-normal">
                  {renderNumber(value?.totalInvestment, true)}
                </div>
                <div className="flex text-base not-italic font-medium text-[#012749] dark:text-blue-800">
                  {renderNumber(value?.currentValue, true)}
                </div>
                <div className={`flex text-base not-italic font-medium ${value?.pnl > 0 ? 'text-lime-600' : 'text-red-500'}`}>
                  {renderNumber(value?.pnl, true)}
                </div>
                <div className="text-base not-italic font-medium text-[#012749] dark:text-blue-800">
                  {`${renderNumber(value?.roiPercentage * 100)}%`}
                </div>
                <div className="text-base not-italic font-medium">
                  {value?.direction ? value.direction : '-'}
                </div>
                <div className="flex gap-2">
                  <span>
                    <img
                      src={`${assetPrefix}/images/${value['24hDelta'] > 0 ? 'GreenArrow' : 'RedArrow'}.svg`}
                      alt="Arrow"
                      className="w-2.5 h-2.5 mt-1.5"
                    />
                  </span>
                  <div className="text-base not-italic font-normal">
                    {`${renderNumber(value['24hDelta'] * 100)}%`}
                  </div>
                </div>
                <div className="flex gap-2">
                  <span>
                    <img
                      src={`${assetPrefix}/images/${value['7dDelta'] > 0 ? 'GreenArrow' : 'RedArrow'}.svg`}
                      alt="Arrow"
                      className="w-2.5 h-2.5 mt-1.5"
                    />
                  </span>
                  <div className="text-base not-italic font-normal">
                    {`${renderNumber(value['7dDelta'] * 100)}%`}
                  </div>
                </div>
                <div className="flex gap-2">
                  <span>
                    <img
                      src={`${assetPrefix}/images/${value['30dDelta'] > 0 ? 'GreenArrow' : 'RedArrow'}.svg`}
                      alt="Arrow"
                      className="w-2.5 h-2.5 mt-1.5"
                    />
                  </span>
                  <div className="text-base not-italic font-normal">
                    {`${renderNumber(value['30dDelta'] * 100)}%`}
                  </div>
                </div>

              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
