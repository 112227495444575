import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../Layout/Layout';
import SettingNavBar from './SettingNavBar';
import { selectLoggedInStatus } from '../../../redux/selectors';
import AccessDenied from './AccessDenied';

const Settings = () => {
  const loggedInStatus = useSelector(selectLoggedInStatus, () => { });

  return (
    <div>
      <Layout>
        {loggedInStatus.userType === 'Trader'
          ? <SettingNavBar />
          : <AccessDenied />}
      </Layout>
    </div>
  );
};

export default Settings;
