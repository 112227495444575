/* eslint-disable import/prefer-default-export */
export const overviewDummy = [
  {
    uv: 10,
    pv: 0,
  },
  {
    uv: -20,
    pv: 0,
  }, {
    uv: 10,
    pv: 0,
  }, {
    uv: 30,
    pv: 600,
  },
  {
    uv: -250,
    pv: 300,
  }, {
    uv: 250,
    pv: 300,
  }, {
    uv: 270,
    pv: 300,
  }, {
    uv: 220,
    pv: 300,
  }, {
    uv: 310,
    pv: 600,
  }, {
    uv: 150,
    pv: 0,
  }, {
    uv: 100,
    pv: 0,
  }, {
    uv: -40,
    pv: -300,
  }, {
    uv: -120,
    pv: -300,
  }, {
    uv: -250,
    pv: -300,
  }, {
    uv: -200,
    pv: -600,
  }, {
    uv: -400,
    pv: -600,
  },

];
export const RoiDummy = [
  {
    name: 'Bitcoin',
    pr: 23,
  },
  {
    name: 'Ethereum',
    pr: 3,
  }, {
    name: 'Ripple',
    pr: 13,
  }, {
    name: 'Litecoin',
    pr: 32,
  }, {
    name: 'Bitcoin Cash',
    pr: 12,
  },
];
export const PieChartDummy = [
  {
    name: 'Bitcoin',
    pr: 17544,
  },
  {
    name: 'Bitcoin Cash',
    pr: 6453,
  }, {
    name: 'Litecoin',
    pr: 2007,
  },
  {
    name: 'Ethereum',
    pr: 1824,
  }, {
    name: 'Ripple',
    pr: 17544,
  },
];

export const ProfitLosssDummy = [
  {
    name: 'Bitcoin',
    invested: 48000,
    // pl: 16000,
    loss: 25000,
    data: 0,
  },
  {
    name: 'Ethereum',
    invested: 26000,
    pl: 13000,
    data: 26000,
  }, {
    name: 'Ripple',
    invested: 11000,
    loss: 8000,
    data: 46000,
  },
  {
    name: 'Litecoin',
    invested: 32000,
    pl: 5000,
    data: 66000,
  }, {
    name: 'Bitcoin Cash',
    invested: 15000,
    loss: 10000,
    data: 86000,
  },
];

export const ListingData = [
  {
    Assets: 'Ethereum',
    Quantity: 0.3,
    TotalInvestment: '$4,789.00',
    CurrentValue: '11,000',
    ProfitLoss: '17,544',
    ROI: '75',
    Delta1: '+0.2%',
    Delta2: '+0.2%',
    Delta3: '+0.2%',
  },
  {
    Assets: 'Ethereum',
    Quantity: 0.3,
    TotalInvestment: '$4,789.00',
    CurrentValue: '11,000',
    ProfitLoss: '17,544',
    ROI: '75',
    Delta1: '+0.2%',
    Delta2: '+0.2%',
    Delta3: '+0.2%',
  }, {
    Assets: 'Ethereum',
    Quantity: 0.3,
    TotalInvestment: '$4,789.00',
    CurrentValue: '11,000',
    ProfitLoss: '17,544',
    ROI: '75',
    Delta1: '+0.2%',
    Delta2: '+0.2%',
    Delta3: '+0.2%',
  }, {
    Assets: 'Ethereum',
    Quantity: 0.3,
    TotalInvestment: '$4,789.00',
    CurrentValue: '11,000',
    ProfitLoss: '17,544',
    ROI: '75',
    Delta1: '+0.2%',
    Delta2: '+0.2%',
    Delta3: '+0.2%',
  }, {
    Assets: 'Ethereum',
    Quantity: 0.3,
    TotalInvestment: '$4,789.00',
    CurrentValue: '11,000',
    ProfitLoss: '17,544',
    ROI: '75',
    Delta1: '+0.2%',
    Delta2: '+0.2%',
    Delta3: '+0.2%',
  },
];
export const ExchangeListingData = [
  {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  },
  {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 100,
    Value: '$200,000.00',
    ProPortion: 110,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  }, {
    Location: 'Ledger',
    Currency: 'BTC',
    Quantity: 99,
    Value: '$200,000.00',
    ProPortion: 100,
    FirstUpdate: 'Saturday, Nov-21-2015',
    LastUpdate: 'Wednesday, Sep-7-2023',
  },
];

export const DistributionDummy = [
  {
    '6 Months Ago': 900,
    Now: 250,
    assets: 'Asset1',
  },
  {
    '6 Months Ago': 500,
    Now: 800,
    assets: 'Asset2',
  },
  {
    '6 Months Ago': 400,
    Now: 350,
    assets: 'Asset3',
  },
  {
    '6 Months Ago': 300,
    Now: 1001,
    assets: 'Asset1',
  },
  {
    '6 Months Ago': 950,
    Now: 650,
    assets: 'Asset1',
  },
];

export const CurrencyDummy = [
  {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  },
  {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  }, {
    Asset: 'BTC',
    Hot: '25%',
    HotValue: '$50,000.00',
    Cold: '75%',
    Quantity: 10,
    Value: '$200,000.00',
    ColdValue: '$150,000.00',
  },
];

export const AssetsTableDummy = [
  {
    crypto: [{
      assets: [
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      trader: [
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      location: [
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
      ],
    }],
  },
  {
    equity: [{
      assets: [
        { Assets: 'Asset1', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      trader: [
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      location: [
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
      ],
    }],
  },
  {
    forex: [{
      assets: [
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
        { Assets: 'Asset', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      trader: [
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
        { Trader: 'Trader', Quantity: 'Quantity', USD: 'USD' },
      ],
    },
    {
      location: [
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
        { Location: 'Location', Quantity: 'Quantity', USD: 'USD' },
      ],
    }],
  },
];
// { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
// { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
// { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
// { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },
