import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { BeatLoader } from 'react-spinners';
import { useLocation } from 'react-router';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { Navigate } from 'react-router-dom';
import { loadingStates } from '../utilities/general';
import { apiWrapWithErrorWithData } from '../utilities/apis/apiHelpers';
import { adminLogin } from '../utilities/apis/adminLogin';
import { setCookiesSession } from '../utilities/cookiesSession';
import actions from '../redux/actions';
import { selectLoggedInStatus } from '../redux/selectors';
import routes from '../router/routes';
import FlashLoader from '../components/CommonFunction/FlashLoader';

const LoginPage = () => {
  const clientId = '244859344861-pnats5dg41io5loulg9umh236kr9cjn1.apps.googleusercontent.com';
  const dispatch = useDispatch();
  const loggedInStatus = useSelector(selectLoggedInStatus, () => {});
  const [loading, setLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const location = useLocation();
  const updateStoreForLogin = (data) => dispatch({
    type: actions.SET_LOGGED_IN_STATUS,
    payload: {
      ...data,
      loggedIn: true,
    },
  });
  const responseGoogleSuccess = (response) => {
    setLoading(loadingStates.LOADING);
    let token = null;
    try {
      if (response.credential) {
        token = response.credential;
      }
    } catch (e) {
      alert('Failed to log in. Please refresh page.');
    }
    if (token) {
      apiWrapWithErrorWithData(adminLogin({ accessToken: token })).then(
        (data) => {
          if (data.success && data.token) {
            setCookiesSession('authToken', JSON.stringify(data), 6);
            updateStoreForLogin(data);
            setLoading(loadingStates.NO_ACTIVE_REQUEST);
          }
        },
      ).catch(() => {
        setLoading(loadingStates.FAILED);
      });
    }
  };
  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: (response) => console.log(response),
    googleAccountConfigs: {
      client_id: clientId,
      callback: responseGoogleSuccess,
    },
  });
  useEffect(() => {
    window.handleGoogleLogin = responseGoogleSuccess;
    return () => {
      window.handleGoogleLogin = () => alert('Login not allowed at this point.');
    };
  }, []);
  if (loggedInStatus.loggedIn) {
    let goToPage = null;
    try {
      goToPage = location.state?.from;
    } catch (e) {
      console.log(e);
    }
    // console.log('Already logged in');
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        {/* <BeatLoader size={10} color="green" /> */}
        <FlashLoader />
        <Navigate to={goToPage || routes.TRADERS_DATA_LISTING} />
      </div>
    );
  }
  return (
    <div className=" w-screen h-screen flex flex-col justify-center items-center">
      <div className="m-2">Welcome, Sign in with your oddup official email.</div>
      {loading === loadingStates.LOADING ? <FlashLoader />
        : (
          !loggedInStatus.loggedIn
          && (
          <div>
            <div
              id="g_id_onload"
              data-client_id={clientId}
              data-callback="handleGoogleLogin"
              data-auto_prompt="false"
            />
            <div
              className="g_id_signin"
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="sign_in_with"
              data-shape="rectangular"
              data-logo_alignment="left"
            />
          </div>
          )
        )}
    </div>
  );
};

export default LoginPage;
