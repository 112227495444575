/* eslint-disable max-len */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { ArrowAutofitContent } from 'tabler-icons-react';
import { useNavigate } from 'react-router';
import Styles from './common.module.css';
import {
  buttonize, assetPrefix, loadingStates, renderNumber,
} from '../../utilities/general';
import FlashLoader from '../CommonFunction/FlashLoader';

export default function BonusPool({ dataLoading, data }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.CompanyContainer}>
      <div className="flex gap-[6.3rem] w-[100%] h-[6rem] border-b-2 pl-9 items-center">
        <div className="flex gap-4">
          <ArrowAutofitContent
            size={25}
            strokeWidth={2}
            color="black"
          />
          <span className={Styles.HeadingText}>Bonus Pool</span>
        </div>
        <span
          {...buttonize((e) => {
            e.stopPropagation();
            navigate('/bonusPool-data-details');
          })}
          className="grid items-center"
        >
          <img src={`${assetPrefix}/images/circleArrow.svg`} alt="content" width={36} height={36} />
        </span>
      </div>
      <div className="justify-center grid w-[100%] gap-6 ">
        <div>
          {dataLoading === loadingStates.FINISHED
            && (
              <>
                <div className="flex pt-6 pb-5 justify-center demo">
                  <div className="grid gap-10 grid-cols-2">
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Total Revenue</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.totalRevenue, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Investor Commission</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.investorsCommission, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Total Payout</span>
                      <span className={`${Styles.Text} ${data.totalPayout > 0 ? 'text-lime-600' : 'text-red-500'}`}>{renderNumber(data.totalPayout, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Internal Commission</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>
                        {renderNumber(data.internalCommission, true)}
                      </span>
                    </div>
                    <div className={`${Styles.LastDaysBox} col-span-2 w-full flex justify-center`}>
                      <div className={`${Styles.LastDays} max-w-max flex justify-center`}>Current Pool</div>
                    </div>

                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Bonus</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.currentPool.bonus, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Trader Commission</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.currentPool.traderCommission, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Investor Commission</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.currentPool.investorCommission, true)}</span>
                    </div>
                    <div className="grid">
                      <span className={`${Styles.MiniText} pb-2`}>Team Commission</span>
                      <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.currentPool.teamCommission, true)}</span>
                    </div>
                  </div>
                </div>

              </>
            )}
          {dataLoading === loadingStates.LOADING
            && (<div className="flex justify-center items-center min-h-[371px] xl:min-h-[407px] 2xl:min-h-[498px]"><FlashLoader /></div>)}
        </div>

      </div>
    </div>
  );
}
