/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { Text, useMantineColorScheme } from '@mantine/core';
import Styles from '../CompanyDataV2.module.css';

export default function SingleLineBarCharts({
  data, type,
}) {
  const { colorScheme } = useMantineColorScheme();
  const assetColor = ['#003A6D', '#026DB0', '#30BEF5'];
  const liabilitiesColor = ['#E07800', '#FC9B41', '#FFD2AC'];
  const [finalData, setFinalData] = useState();

  useEffect(() => {
    if (data) {
      const newArray = [{}];
      Object.entries(data).forEach(([share, percentage]) => {
        newArray[0][share] = percentage * 100;
      });
      setFinalData(newArray);
    }
  }, [data]);

  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
          payload.map((entry, index) => (
            <div className="flex items-center gap-1.5">
              <span
                style={{
                  backgroundColor: entry.color,
                }}
                className="h-3.5 w-3.5 rounded-[3px]"
              />
              <span key={`item-${index}`} className={`flex font-normal not-italic text-sm ${colorScheme === 'dark' ? '#beb9b9' : 'text-gray-700'}`}>{entry?.value?.charAt(0).toUpperCase() + entry?.value?.slice(1)}</span>
            </div>
          ))
        }
      </>
    );
  };
  const RenderLabel = (label) => {
    const {
      x, y, width, value, height,
    } = label;
    return (
      <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle" dy={5}>
        {`${value >= 10 ? `${Math.round(value)}%` : ''}`}
      </text>
    );
  };

  return (
    <div className="w-full h-[6rem]">
      <div className="py-5 px-6">
        <span className={`${Styles.DistributionTitle} flex items-baseline`}>
          {type}
        </span>
        {/* {dataLoading === loadingStates.LOADING
          && <div className=" flex justify-center w-full h-[8rem] items-center"><Loader variant="dots" /></div>}
        {dataLoading === loadingStates.FINISHED
          && ( */}
        <div className="pt-4">
          <ResponsiveContainer width="99%" height={75}>
            <BarChart
              layout="vertical"
              width={700}
              height={200}
              data={finalData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis type="number" hide axisLine={false} domain={['min', 'max']} />
              <YAxis hide axisLine={false} type="category" />
              <Tooltip
                cursor={{ fill: 'none' }}
                content={({ payload }) => (
                  <div className="border border-solid bg-white p-2 opacity-80">
                    <div className="grid">
                      {payload?.map((entry) => (
                        <div style={{ display: 'flex', color: entry.color }}>
                          <Text mr="5px">{entry.name}</Text>
                          :
                          <Text ml="5px">{`${entry?.value?.toFixed(2)}%`}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              />
              <Legend
                content={renderLegend}
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  marginTop: '-4px',
                  top: '0px',
                  paddingBottom: '0px',
                  display: 'flex',
                  gap: 12,
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
                iconType="square"
                iconSize={12}
              />

              {finalData?.map((item) => Object.keys(item).map((value, index) => (
                <Bar dataKey={value} stackId="stack" fill={type === 'Asset Distribution' ? assetColor[index] : liabilitiesColor[index]}>
                  <LabelList dataKey={value} position="center" content={RenderLabel} />
                </Bar>
              )))}

            </BarChart>
          </ResponsiveContainer>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
