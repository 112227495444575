/* eslint-disable max-len */
import React from 'react';
import { Mist } from 'tabler-icons-react';
import { useNavigate } from 'react-router';
import Styles from './common.module.css';
import {
  assetPrefix, buttonize, loadingStates, renderNumber,
} from '../../utilities/general';
import DistributionBarChart from './DistributionBarChart';
import FlashLoader from '../CommonFunction/FlashLoader';

export default function BalanceSheet({ data, dataLoading }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.CompanyContainer}>
      <div className="flex gap-[4.5rem] w-[100%] h-[6rem] justify-start items-center pl-9  border-b-2">
        <div className="flex gap-4">
          <Mist
            size={25}
            strokeWidth={2}
            color="black"
          />
          <span className={Styles.HeadingText}>Balance Sheet</span>
        </div>
        <span
          {...buttonize((e) => {
            e.stopPropagation();
            navigate('/balance-sheet-details');
          })}
          className="grid items-center"
        >
          <img src={`${assetPrefix}/images/circleArrow.svg`} width={36} height={36} alt="content" />
        </span>
      </div>
      <div className="justify-center grid w-[100%] gap-6 ">
        {/* <div className="pt-16 w-full justify-center flex">
          <span className={Styles.Loader}>
            <Loader
              size={25}
              strokeWidth={2}
              color="black"
            />
          </span>
        </div>
        <div>
          <div className={Styles.CommingSoonText}>
            COMING
            <br />
            SOON
          </div>
        </div> */}
        <div>
          { dataLoading === loadingStates.FINISHED
      && (
        <>
          <div className="flex pt-6 pb-5 justify-center demo">
            <div className="grid gap-10 grid-cols-2">
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Total Assets</span>
                <span className={`${Styles.Text} ${data?.totalAssets > 0 ? 'text-lime-600' : 'text-red-500'}`}>{renderNumber(data.totalAssets, true)}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Total Liabilities</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.totalLiabilities, true)}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Book Value - Hot</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>{renderNumber(data.bookValueHot, true)}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Book Value - Cold</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>
                  {renderNumber(data.bookValueCold, true)}
                </span>
              </div>
              <div className={`${Styles.LastDaysBox} col-span-2 w-full flex justify-center`}>
                <div className={`${Styles.LastDays} max-w-max flex justify-center`}>Stratification</div>
              </div>
            </div>
          </div>
          <div className="grid 2xl:gap-5 md:gap-9 pt-2">
            <DistributionBarChart type="Asset Distribution" data={data.assetDistribution} />
            <DistributionBarChart type="Liabilities Distribution" data={data.liablityDistribution} />
          </div>
        </>
      )}
          { dataLoading === loadingStates.LOADING
      && (<div className="flex justify-center items-center min-h-[371px] xl:min-h-[407px] 2xl:min-h-[498px]"><FlashLoader /></div>)}
        </div>

      </div>
    </div>
  );
}
