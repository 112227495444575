/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

export const getAccessLevelsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-settings/access-levels`, {
  headers: {
    ...headersProvider(),
  },
});

export const setUserRoleApi = (data) => axios.put(`${constants.tradersApiHost}/company/v2-settings/set-role`,
  [
    ...data,
  ],
  {
    // noTrailingSlash: true,
    headers: headersProvider(),
  });

export const setAccessLevelsApi = (data) => axios.put(`${constants.tradersApiHost}/company/v2-settings/access-levels`,
  {
    ...data,
  },
  {
    headers: headersProvider(),
  });
