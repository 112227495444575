/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import {
  Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip,
} from 'recharts';
import styles from '../CompanyDataV2.module.css';
import { renderNumber } from '../../../../utilities/general';

export default function PieCharts({ pieData }) {
//   const { chartName } = props;
  const [activeIndex, setActiveIndex] = useState();
  // const FinalPieData = [
  //   {
  //     name: 'Back to company',
  //     amt: 10 * 0.5,
  //   },
  //   {
  //     name: 'Trader commission',
  //     amt: 12 * 0.2,
  //   }, {
  //     name: 'Team commission',
  //     amt: 14 * 0.2,
  //   },
  //   {
  //     name: 'Investor commission',
  //     amt: 20 * 0.1,
  //   },
  // ];
  const COLORS = ['#003A6D', '#026DB0', '#49A1EC', '#30BEF5', '#30BEF5'];
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  const onPieLeave = useCallback(
    () => {
      setActiveIndex(undefined);
    },
    [setActiveIndex],
  );
  const renderActiveShape = (items) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = items;

    return (
      <g style={{ backgroundColor: 'red' }}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const CustomTooltip = ({ payload }) => (
    <div className="bg-orange-400 px-2 text-white">
      {`${payload[0]?.payload.entity} : ${renderNumber(payload[0]?.payload.value, true)}`}
    </div>
  );
  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
            payload.map((entry, index) => (
              <div className="flex items-baseline gap-0.5">
                <span
                  style={{
                    backgroundColor: entry.color,
                  }}
                  className="h-3 w-3 rounded-[0.188rem]"
                />
                <span key={`item-${index}`} className={`${styles.LegendText} 2xl:!text-[0.79rem]`}>{entry.value.charAt(0).toUpperCase() + entry.value.slice(1)}</span>
              </div>
            ))
          }
      </>
    );
  };
  return (
    <div className="px-5 py-3">
      <div className={`${styles.HeadingText} w-[40px]`}>Shareable Profit</div>

      <div className="flex justify-center">
        <div className="w-[99%] flex justify-center">
          <ResponsiveContainer width={345} height={170}>
            <PieChart>
              <Legend
                content={renderLegend}
                verticalAlign="middle"
                align="left"
                width="auto"
                wrapperStyle={{
                  margin: '0.5rem 0rem',
                  gap: 5,
                  display: 'grid',
                }}
                iconType="square"
                iconSize={12}
              />
              <Pie
                data={pieData?.shares}
                dataKey="value"
                cx={240}
                // cy={75}
                nameKey="entity"
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                legendType="square"
                blendStroke
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                onMouseEnter={onPieEnter}
                onMouseLeave={onPieLeave}
              >
                {pieData?.shares?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={CustomTooltip} />
              <text
                x="71%"
                y="40%"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#58667E"
                fontSize={16}
                fontWeight={400}
                fontFamily="SF Pro Display"
              >
                Total Profit
              </text>
              <text
                x="71%"
                y="57%"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#58667E"
                fontSize={22}
                fontWeight={700}
                fontFamily="SF Pro Display"
              >
                {renderNumber(pieData?.total, true)}
              </text>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
