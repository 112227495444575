/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Button, Input, useMantineColorScheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Pencil, Trash, X } from 'tabler-icons-react';
import { formatISO } from 'date-fns';
import commaNumber from 'comma-number';

function CustomInput({
  liabilityData, label, setPostData, setShouldCallApi, totalDatas,
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [totalData, setTotalData] = useState(totalDatas || []);
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [minDateVal, setMinDateVal] = useState(null);
  const [maxDateVal, setMaxDateVal] = useState(null);
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    setTotalData(totalDatas);
  }, [totalDatas]);

  useEffect(() => {
    if (liabilityData.length) {
      Object.keys(liabilityData[0]?.value).forEach((key) => {
        formData[key] = null;
      });
    }
  }, [totalData, liabilityData]);

  useEffect(() => {
    setPostData({ new_liability_data: totalData });
  }, [totalData]);

  const dollarSign = ['capital', 'associatedFee', 'principalAmount', 'amount', 'loanAmount', 'currentMarketValue', 'currentBalance', 'amountOwed',
  ];
  const percentageSign = ['interestRate', 'rateOfInterest'];
  function formatInputValue(data, key) {
    if (data && dollarSign.includes(key)) {
      return `$${commaNumber(data)}`;
    }
    if (data && percentageSign.includes(key)) {
      return `${data}%`;
    }
    return data;
  }

  const stringField = [
    'date',
    'vendor',
    'dueDate',
    'creditor',
    'lender',
    'maturityDate',
    'source',
    'expirationDate',
    'type',
    'debitor',
    'bankName',
    'location',
    'remark',
  ];
  const numField = [
    'capital',
    'loanAmount',
    'amountOwed',
    'interestRate',
    'tenureInMonths',
    'amount',
    'associatedFee',
    'paymentTenureInMonths',
    'rateOfInterest',
    'repaymentTenureInMonths',
    'principalAmount',
    'currentMarketValue',
    'control',
    'currentBalance',
    'accountNumber',
  ];
  const isAllValuesNull = (obj) => {
    for (const key in obj) {
      if (obj[key] === null) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    if (label === 'capital' && liabilityData.length === 1 && !isAllValuesNull(liabilityData[0].value)) {
      setOpen(true);
    }
  }, []);
  const keyToLabel = (key) => {
    const words = key?.match(/[a-z]+|[A-Z][a-z]+|[A-Z]+(?=_|$)|\d+/g);
    return words ? words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : key;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({ ...prev, blankFields: '' }));
    let strippedValue = value.replace('$', '').replace(/,/g, '');

    if (value.includes('%')) {
      strippedValue = strippedValue.replace(/[^0-9.]/g, '');
    }

    if (percentageSign.includes(name) && value !== '' && e.nativeEvent.inputType === 'deleteContentBackward') {
      strippedValue = strippedValue.slice(0, -1);
    }
    if (/[^0-9.]/.test(strippedValue) && numField.includes(name)) {
      setErrors((prev) => ({ ...prev, [name]: `${keyToLabel(name)} must be a number` }));
    } else if (/[^a-zA-Z\s]+$/.test(strippedValue) && stringField.includes(name) && strippedValue.trim() !== '') {
      setErrors((prev) => ({ ...prev, [name]: `${keyToLabel(name)} must be a text` }));
    } else {
      const formattedValue = strippedValue;
      setErrors((prev) => ({ ...prev, [name]: '' }));
      setFormData((prev) => ({ ...prev, [name]: formattedValue }));
    }
  };

  const onSave = () => {
    const newErrors = {};
    Object.keys(liabilityData[0].value).forEach((key) => {
      if ((stringField.includes(key) || numField.includes(key)) && !formData[key]) {
        newErrors[key] = `${keyToLabel(key)} is required`;
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }

    if (editingId !== null) {
      const keys = Object.keys(totalData);
      const updatedData = { ...totalData };

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        updatedData[key] = updatedData[key].map((item) => {
          if (item.addedOn === editingId) {
            return {
              ...item,
              lastUpdatedOn: formatISO(new Date(), { format: 'extended' }),
              value: formData,
            };
          }
          return item;
        });
      }
      setTotalData(updatedData);
      setShouldCallApi(true);
      setEditingId(null);
    } else {
      const existingEntryIndex = totalData[label].findIndex((item) => item.subType === label && !isAllValuesNull(item.value));
      if (existingEntryIndex !== -1) {
        const updatedData = { ...totalData };
        updatedData[label][existingEntryIndex] = {
          ...updatedData[label][existingEntryIndex],
          addedOn: formatISO(new Date(), { format: 'extended' }),
          lastUpdatedOn: formatISO(new Date(), { format: 'extended' }),
          value: formData,
        };
        setTotalData(updatedData);
        setShouldCallApi(true);
        setOpen(false);
        setFormData({});
        setErrors({});
      } else {
        const newData = {
          type: liabilityData[0].type,
          subType: label,
          addedOn: formatISO(new Date(), { format: 'extended' }),
          lastUpdatedOn: formatISO(new Date(), { format: 'extended' }),
          uuid: liabilityData[0].uuid,
          value: formData,
        };
        setTotalData((prevData) => ({
          ...prevData,
          [label]: [...prevData[label], newData],
        }));
      }
      setShouldCallApi(true);
      setOpen(false);
      setFormData({});
      setErrors({});
    }

    setOpen(false);
    setFormData({});
    setErrors({});
  };

  const onEdit = (addedOn) => {
    const editData = totalData[label];

    const itemToEdit = editData.find((item) => item.addedOn === addedOn);
    if (itemToEdit) {
      setFormData(itemToEdit.value);
      setEditingId(addedOn);
      setOpen(true);
    }
  };
  const onDelete = (id, uuid, value) => {
    const obj = {};
    Object.keys(value).map((item) => {
      obj[item] = null;
      return obj;
    });
    const filteredData = totalData[label]?.map((item) => item);
    const dataLength = filteredData.length;
    if (dataLength === 1) {
      const itemIndex = totalData[label].findIndex((item) => item.uuid === uuid);
      const updatedData = { ...totalData };
      updatedData[label][itemIndex] = {
        ...updatedData[label][itemIndex],
        addedOn: null,
        lastUpdatedOn: null,
        value: obj,
      };
      setTotalData(updatedData);
      setShouldCallApi(true);
    } else {
      const updatedData = totalData[label].filter((item) => item.addedOn !== id);
      setTotalData((prevTotalData) => ({
        ...prevTotalData,
        [label]: updatedData,
      }));
      setShouldCallApi(true);
    }
    if (id === editingId) {
      setOpen(false);
      setFormData({});
      setErrors({});
      setEditingId(null);
    }
  };

  const onDateChange = (value, key) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setFormData({});
    setEditingId(null);
  };
  const onAdd = () => {
    setOpen(true);
  };

  let Count = 0;

  useEffect(() => {
    const index = totalData[label]?.findIndex((item) => item.addedOn === editingId);
    if (totalData && label === 'capital') {
      if (index === -1) {
        const highestDateObject = totalData[label]?.reduce((prev, current) => (
          (new Date(prev?.value?.date) > new Date(current?.value?.date)) ? prev : current
        ), {});
        setMinDateVal(new Date(highestDateObject?.value?.date));
      } else {
        setMinDateVal(index === 0 ? null : new Date(totalData[label][index - 1]?.value?.date));
      }
    }
    if (label === 'capital' || label === 'loan' || label === 'overdraft') {
      setMaxDateVal(new Date());
    } else {
      setMaxDateVal(null);
    }
  }, [open, editingId]);

  return (
    <div className="w-[75rem] gap-7 grid min-h-full">
      <div className="flex gap-6 items-center not-italic font-medium">
        <div className="text-[1.75rem]">{`${keyToLabel(label)}`}</div>
        <div onClick={onAdd} className="text-[#33B1FF] underline cursor-pointer flex text-lg items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M12 22.5C6.47967 22.494 2.00606 18.0204 2 12.5V12.3C2.10993 6.80455 6.63459 2.42797 12.1307 2.5009C17.6268 2.57382 22.0337 7.0689 21.9978 12.5654C21.9619 18.0618 17.4966 22.4989 12 22.5ZM7 11.5V13.5H11V17.5H13V13.5H17V11.5H13V7.50002H11V11.5H7Z" fill="#33B1FF" />
            </svg>
          </div>
          <div> Add</div>
        </div>
      </div>
      <div className="gap-3.5 grid">
        {liabilityData?.map((data) => {
          if (data?.subType === 'capital' && isAllValuesNull(data?.value)) {
            Count += 1;
          }
          return (
            isAllValuesNull(data.value)
            && (
              <div className={`${colorScheme === 'dark' ? 'bg-black text-[#beb9b9] border-[1px] border-stone-700' : 'bg-white text-[#3A3A3A] border-[#98A9BC]'} border-l-8 rounded-2xl whitespace-nowrap`}>
                <div key={data.subType} className="w-full gap-3 rounded-lg p-5 flex justify-between h-full">
                  {data?.value && Object.keys(data?.value)?.map((key, i) => (
                    <div key={i} className={`${Count === 1 && key === 'capital' && liabilityData?.length > 1 ? 'gap-[4.9rem]' : 'gap-2'} flex items-center`}>
                      <div className="text-base not-italic font-medium capitalize">{Count > 1 && label === 'capital' && key === 'capital' ? `Additional ${keyToLabel(key)}` : keyToLabel(key)}</div>
                      {!key.toLowerCase().includes('date') ? (
                        <Input
                          placeholder={`Your ${keyToLabel(key)}`}
                          radius="md"
                          value={formatInputValue(data.value[key], key) || ''}
                          readOnly
                          required
                          sx={{
                            minWidth: 20,
                            '.mantine-Input-input:focus': {
                              borderColor: '#ced4da',
                            },
                          }}
                        />
                      ) : (
                        <DatePicker
                          placeholder="Pick date"
                          inputFormat="DD/MM/YYYY"
                          labelFormat="MM/YYYY"
                          value={new Date(formatISO(new Date(data.value[key]), { representation: 'date' })) || ''}
                          readOnly
                          disabled
                          required
                          clearable={false}
                          sx={{
                            minWidth: 20,
                            '.mantine-Input-input:focus': {
                              borderColor: '#CFD3D4',
                            },
                            '.mantine-Input-input:disabled': {
                              color: '#000',
                              backgroundColor: '#fff',
                              borderColor: '#CFD3D4',
                              opacity: 'unset',
                              cursor: 'text',
                            },
                          }}
                        />
                      )}
                    </div>
                  ))}
                  <div className="flex items-center gap-3">
                    <div onClick={() => onEdit(data.addedOn)}>
                      <Pencil
                        size={22}
                        strokeWidth={2}
                        color={colorScheme === 'dark' ? 'white' : 'grey'}
                        cursor="pointer"
                      />
                    </div>
                    <div onClick={() => onDelete(data.addedOn, data.uuid, data.value)}>
                      <Trash
                        size={22}
                        strokeWidth={2}
                        color="red"
                        cursor="pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
        {open && <div className="border-b-2 mb-0 w-full border-[#CFD3D4] hidden block-borders" />}
      </div>
      {open && (
        <div className={`${colorScheme === 'dark' ? 'bg-black border-[1px] border-stone-700' : 'bg-white'} w-full h-full  rounded-lg`}>
          <div className="flex justify-end pt-5 pr-5">
            <X
              size={20}
              strokeWidth={2.2}
              className="cursor-pointer"
              color="grey"
              onClick={() => handleClose()}
            />
          </div>
          <div className="gap-6 grid pb-10 pl-10 pt-0">
            <div className="grid grid-cols-2 gap-6">
              {liabilityData.length && Object?.keys(liabilityData[0]?.value)?.map((key, index) => (
                <div key={index} className="w-[300px]">
                  {!key.toLowerCase().includes('date') ? (
                    <Input.Wrapper
                      label={<div className={`${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-black'} my-3`}>{Count > 0 && label === 'capital' ? `Additional ${keyToLabel(key)}` : keyToLabel(key)}</div>}
                      radius="lg"
                      size="lg"
                    >
                      <Input
                        placeholder={`Your ${keyToLabel(key)}`}
                        id={key}
                        name={key}
                        className="capitalize"
                        value={formatInputValue(formData[key], key) || ''}
                        // value={`$${formData[key] || ''}`}
                        onChange={handleInputChange}
                        radius="md"
                        size="md"
                      />
                      {errors[key] && <div className="text-red-500">{errors[key]}</div>}
                    </Input.Wrapper>
                  ) : (
                    <>
                      <DatePicker
                        placeholder="Pick date"
                        name={key}
                        value={formData[key] ? new Date(formatISO(new Date(formData[key]), { representation: 'date' })) : ''}
                        onChange={(value) => onDateChange(value, key)}
                        label={<div className={`${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-black'} my-3`}>{keyToLabel(key)}</div>}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="MM/YYYY"
                        maxDate={maxDateVal}
                        minDate={minDateVal}
                        radius="md"
                        size="md"
                        // color={colorScheme === 'dark' ? 'black' : 'white'}
                      />
                      {errors[key] && <div className="text-red-500">{errors[key]}</div>}
                    </>
                  )}
                </div>
              ))}
            </div>
            {errors.blankFields && <div className="text-red-500">{errors.blankFields}</div>}
            <div>
              <Button className="h-10 text-base not-italic font-normal bg-[#33B1FF]" radius="sm" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default CustomInput;
