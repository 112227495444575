import React from 'react';
import {
  string,
} from 'prop-types';

import { useMantineColorScheme } from '@mantine/core';
import styles from './HeaderCell.module.css';

const HeaderCell = (props) => {
  const { text, classes, subHeading } = props;
  const { colorScheme } = useMantineColorScheme();
  return (
    <div className={`flex items-start justify-center ${classes} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#000000]'}`}>
      <div className="grid">
        <span className={styles.headerText}>{text}</span>
        {subHeading && <span className="!text-[0.72rem] text-[#898989] font-normal">{subHeading}</span>}
      </div>
    </div>
  );
};

HeaderCell.defaultProps = {
  classes: '',

};
HeaderCell.propTypes = {
  text: string.isRequired,
  classes: string,

};

export default HeaderCell;
