import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const initialStore = {
  loggedInStatus: { loggedIn: false },
};

const store = configureStore(
  { reducer: rootReducer, devTools: true, preloadedState: initialStore },
);

export default store;
