const Data = [
  {
    name: 'Trader1',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  },
  {
    name: 'Trader2',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  },
  {
    name: 'Trader3',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader4',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader5',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader6',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader7',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader8',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader9',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  }, {
    name: 'Trader10',
    Id: 'MC12KLU',
    status: 'Open PlateForm',
    Equity: '$8,274.75',
    ReturnRate: '$8,274.75',
    WinRate: '68%',
    totalPortfolioSize: '$12,159.13',
    ProfitLoss: '$4,274.75',
    Commision: '$8,595.35',
  },
];
export default Data;
