/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMantineColorScheme } from '@mantine/core';
import { assetPrefix } from '../../../../utilities/general';
import styles from '../../CommonDetailsComponent/TradersDetails.module.css';
import TabBar from '../../../../components/TabBar/TabBar';
import ExchangeTab from './ExchangeTab';
// import BonusPoolOverview from '../../../../components/Overview/CompanyDataOverview/BonusPoolOverview';
// import { overviewDummy } from '../BonusPoolDummy';
import FinancialTab from './FinancialTab';
import BalanceSheetTab from './BalanceSheetTab';
import CurrencyTab from './CurrencyTab';

export default function BalanceSheetDetails() {
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  const [tabName, changeTab] = useState('balanceSheet');
  //  const { pathname } = useLocation();

  const tabBarOptions = [
    {
      label: 'Balance Sheet',
      value: 'balanceSheet',
    },
    {
      label: 'Financials',
      value: 'financials',
    },
    {
      label: 'Currency',
      value: 'currency',
    },
    {
      label: 'Exchange',
      value: 'exchange',
    },
  ];
  const handleClose = () => {
    navigate('/company-data-V2');
  };
  return (
    <div className={colorScheme === 'dark' && styles.dark}>
      <div
        className={`w-[82%] flex h-full absolute right-0 top-0 z-40 ${styles.modalPopup}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          aria-label="button"
          type="button"
          onClick={handleClose}
          className={`absolute top-0 left-0 z-40 w-10 h-10 focus:outline-none ${styles.closeBtn}`}
        >
          <img src={`${assetPrefix}/images/Union.svg`} className="w-3 mx-auto" />
        </button>
        <div
          className="flex flex-col w-full"
          style={{
            height: 'fit-content',
          }}
        >
          <div className={`border-b sticky top-0 z-20 ${colorScheme === 'dark' && 'border-neutral-700'}`}>
            <div className="flex flex-wrap" id={styles.tabsId}>
              <div className="w-full px-14 pt-5 pb-3">
                <TabBar
                  options={tabBarOptions}
                  tabName={tabName}
                  handleChange={(e) => changeTab(e)}
                />
              </div>
            </div>
          </div>

          {/* <div className="w-full top-0 z-10 py-6  px-8 pb-0">
          {tabName !== 'balanceSheet'
        && (
          <BonusPoolOverview overviewDummy={overviewDummy} />
        )}
        </div> */}

          <div className="px-0 relative w-full">
            <div
              className="absolute w-full h-full top-0 left-0"
            >
              {
                tabName === 'financials'
                && (
                  <FinancialTab />
                )
              }
              {
                tabName === 'exchange'
                && (
                  <ExchangeTab />
                )
              }
              {
                tabName === 'balanceSheet'
                && (
                  <BalanceSheetTab />
                )
              }
              {
                tabName === 'currency'
                && (
                  <CurrencyTab />
                )
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
