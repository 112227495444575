/* eslint-disable no-return-assign */
import React from 'react';
import { assetPrefix } from '../../../utilities/general';

const AccessDenied = () => (
  <div className="flex justify-center items-center h-[calc(100vh-8rem)]">
    <div className="p-20 bg-[#FAFAFA] dark:bg-black opacity-80 rounded-md place-self-center mt-10 dark:border border-stone-700">
      <div className="grid justify-center">
        <div className="flex justify-center">
          <img src={`${assetPrefix}/images/warning.svg`} alt="warning" />
        </div>
        <div className="text-center text-[#243E60] text-3xl not-italic font-semibold mt-[3.375rem]">
          Access Denied
        </div>
        <span className="text-center text-xl not-italic font-medium text-[#3A3A3A] dark:text-inherit mt-10">We’re sorry, but you do not have the necessary permissions to access this page.</span>
        <span className="text-center text-base not-italic font-normal text-[#4A4A4A] dark:text-inherit mt-3">
          If you believe this is an error or you need access, please contact the site
          <br />
          administrator for assistance.
        </span>
        <span className="text-center text-xl not-italic mt-3">
          {/* <span className="text-[#3A3A3A] font-semibold">Contact Admin: </span> */}
          <span className="text-[#0072C3] font-normal cursor-pointer" onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=tech-internal@oddup.com', '_blank', 'noopener,noreferrer')}>tech-internal@oddup.com</span>
        </span>
        <span className="text-center text-base not-italic font-normal text-[#4A4A4A] dark:text-inherit mt-3">Thank you for your understanding.</span>
      </div>
    </div>
  </div>
);

export default AccessDenied;
