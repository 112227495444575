import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Menu, useMantineColorScheme } from '@mantine/core';
import {
  ChevronDown, DoorExit, MoonStars, SunHigh,
} from 'tabler-icons-react';
import { selectLoggedInStatus } from '../../redux/selectors';
import styles from './Layout.module.css';
import { assetPrefix } from '../../utilities/general';
import { deleteCookie } from '../../utilities/cookiesSession';
import actions from '../../redux/actions';

const Header = () => {
  const loggedInUser = useSelector(selectLoggedInStatus);
  const dispatch = useDispatch();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const updateStoreForLogout = () => dispatch({
    type: actions.SET_LOGGED_IN_STATUS,
    payload: {
      loggedIn: false,
    },
  });
  const logout = () => {
    updateStoreForLogout();
    deleteCookie('authToken');
  };
  return (
    <div className={`flex flex-row justify-between items-center  ${styles.headerContainer} ${colorScheme === 'dark' && '!bg-[#1a1b1e]'}`}>
      <img src={`${assetPrefix}/logo/${colorScheme === 'dark' ? 'oddupDarkMode.svg' : 'oddup.svg'}`} alt="Oddup" className={`${styles.companyLogo}`} />
      <div onClick={() => toggleColorScheme()} className={styles.themeIcon}>
        {colorScheme === 'dark'
          ? (
            <SunHigh
              size={25}
              strokeWidth={2}
              color="#bfb440"
            />
          )
          : (
            <MoonStars
              size={25}
              strokeWidth={2}
              color="#495057"
            />
          )}
      </div>
      <Menu>
        <Menu.Target>
          <div className={`${styles.loggedInUser} flex items-center cursor-pointer`}>
            <Avatar
              imageProps={{
                referrerpolicy: 'no-referrer',
              }}
              radius="xl"
              src={loggedInUser.picture || `${assetPrefix}/images/default-user-image.png`}
            />
            <div className={`${styles.signedInContainer} flex flex-col`}>
              <span className={styles.signedIn}>Signed in as</span>
              <span className={`${colorScheme === 'dark' && '!text-[#beb9b9]'} ${styles.oddup3}`}>{loggedInUser.name || 'Oddup 3.0'}</span>
            </div>
            <ChevronDown className="ml-2" />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              logout();
            }}
            color="red"
            icon={<DoorExit size={14} />}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

    </div>
  );
};

export default Header;
