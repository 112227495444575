/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@mantine/core';
import React from 'react';
import commonStyle from '../ListingTables/ListingCommonStyle.module.css';

export default function TraderLoadingPlaceholder() {
  return (
    <section>
      {Array(20)
        .fill()
        .map((item, index) => (
          <div key={index} className={`relative dark:bg-black ${commonStyle.TraderList} ${commonStyle.shadowStyle}`}>
            <div
              className={`grid items-center px-[0.7rem] py-[1.45rem] justify-items-center ${commonStyle.dataRow} ${commonStyle.traderRowGrid}`}
            >
              <div className="flex">
                <Skeleton circle height={56} width={65} />
                <div className="flex flex-col ml-4 items-start">
                  <div className="flex items-center justify-center">
                    <Skeleton width={70} height={20} mt={6} />
                  </div>
                  <div className="flex items-center justify-center">
                    <Skeleton width={65} height={15} mt={6} />
                  </div>
                </div>
              </div>

              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              <div className={commonStyle.tableCell}>
                <Skeleton width={60} height={18} />
              </div>
              {/* <div className={commonStyle.tableCell}>
                <Skeleton width={10} height={14} />
              </div> */}
            </div>
          </div>
        ))}
    </section>
  );
}
