/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@mantine/core';
import React from 'react';
import styles from '../../pages/DetailPages/CompanyData/BalanceSheet/BalanceSheet.module.css';

export default function ExchangeLoading() {
  return (
    <section>
      {Array(10)
        .fill()
        .map((item, index) => (
          <div key={index} className={`${styles.rowContainer} items-center`}>
            <div className={`grid inline items-center transition-transform px-4 ${styles.ExchangeRowGrid}`}>
              <div className="flex flex-cols gap-0">
                <Skeleton height={15} width={55} />
              </div>
              <div className="flex items-start gap-3 justify-start">
                <Skeleton circle height={38} width={36} />
                <Skeleton height={15} width={55} mt={10} />
              </div>
              <div>
                <Skeleton height={15} width={55} />
              </div>
              <div className="flex flex-cols gap-0">
                <Skeleton height={15} width={55} />
              </div>
              <div>
                <Skeleton height={15} width={55} />
              </div>
              <div>
                <Skeleton height={15} width={120} />
              </div>
              <div>
                <Skeleton height={15} width={120} />
              </div>
            </div>
          </div>
        ))}
    </section>
  );
}
