import {
  Button,
  Select, Table,
  useMantineColorScheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import { apiWrapWithErrorWithData } from '../../../utilities/apis/apiHelpers';
import { setAccessLevelsApi } from '../../../utilities/apis/setting';
import { loadingStates } from '../../../utilities/general';

const UserAuthorization = ({ authData }) => {
  const [selectedValues, setSelectedValues] = useState({ Trader: {}, Admin: {} });
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const { colorScheme } = useMantineColorScheme();
  useEffect(() => {
    if (authData) {
      const initialSelectedValues = Object.keys(authData.Trader).reduce((acc, key) => {
        acc.Trader[key] = authData.Trader[key];
        acc.Admin[key] = authData.Admin[key];
        return acc;
      }, { Trader: {}, Admin: {} });
      setSelectedValues(initialSelectedValues);
    }
  }, [authData]);

  const handleSelectChange = (keyName, group, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [group]: {
        ...prevValues[group],
        [keyName]: value,
      },
    }));
  };

  const setAccessLevelsData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(setAccessLevelsApi(selectedValues));
    if (resp?.success) {
      setDataLoading(loadingStates.FINISHED);
      showNotification({
        color: 'green',
        message: 'Successfully Updated Access Levels Data.',
      });
    } else {
      setDataLoading(loadingStates.FAILED);
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load set Access Levels Data.',
      });
    }
  };

  const renderSelect = (group, keyName) => (
    <Select
      value={selectedValues[group][keyName]}
      onChange={(value) => handleSelectChange(keyName, group, value)}
      placeholder="Pick one"
      data={['Full', 'write-only', 'read-only'].map((option) => ({
        value: option,
        label: option,
      }))}
      rightSection={<ChevronDown size={14} />}
      rightSectionWidth={30}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      variant="unstyled"
      dropdownPosition="bottom"
    />
  );

  const Rows = Object.keys(selectedValues.Trader).map((keyName) => (
    <tr key={keyName} className="bg-slate-50">
      <td className="font-semibold bg-gray-100 dark:bg-inherit w-1/4 capitalize">{keyName}</td>
      <td className="w-1/4">{renderSelect('Trader', keyName)}</td>
      <td className="w-1/4">{renderSelect('Admin', keyName)}</td>
    </tr>
  ));

  return (
    <div className="w-[80%] p-10">

      <Table
        withBorder
        withColumnBorders
        sx={{
          '& thead tr th': {
            borderColor: colorScheme === 'dark' && '#5c5a5a',
            color: 'inherit',
          },
          '& tbody tr td': {
            borderColor: colorScheme === 'dark' && '#5c5a5a',
          },
        }}
      >
        <thead>
          <tr className="font-bold bg-gray-100 ">
            <th>Sections \ Groups</th>
            <th>Trader</th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>{Rows}</tbody>
      </Table>
      <div className="justify-end flex mt-4">
        <Button
          sx={{
            '&[data-disabled]': { background: '#d8d7d7', color: 'white' },
          }}
          type="button"
          className="h-10 text-base not-italic font-normal bg-[#33B1FF]"
          // disabled={!admin}
          radius="sm"
          loaderPosition="center"
          onClick={setAccessLevelsData}
          loading={dataLoading === loadingStates.LOADING}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserAuthorization;
