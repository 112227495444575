/* eslint-disable max-len */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import { showNotification } from '@mantine/notifications';
import { useLocation, useNavigate } from 'react-router';
import Layout from '../../Layout/Layout';
import CompanyVisits from '../../../components/CompanyVisits/CompanyVisits';
import commonStyle from '../common.module.css';
import { companyDataApi } from '../../../utilities/apis/companyData';
import { apiWrapWithErrorWithData } from '../../../utilities/apis/apiHelpers';
import { loadingStates } from '../../../utilities/general';
import BalanceSheetDetails from '../../DetailPages/CompanyData/BalanceSheet/BalanceSheetDetails';

const CompanyBookDataDetails = lazy(() => import('../../DetailPages/CompanyData/CompanyBook/CompanyBookDataDetails'));
const BonusPoolDataDetails = lazy(() => import('../../DetailPages/CompanyData/BonusPool/BonusPoolDataDetails'));

function CompanyData() {
  const [companyDataConfigs, setCompanyDataConfigs] = useState({
    dataLoading: loadingStates.NO_ACTIVE_REQUEST,
    // data: null,
    companyData: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const CompanyBookDetailsOverlayShow = !!location.pathname.includes('/company-book-details');
  const BonusPoolDetailsOverlayShow = !!location.pathname.includes('/bonusPool-data-details');
  const BalanceSheetDetailsOverlayShow = !!location.pathname.includes('/balance-sheet-details');

  const getCompanyData = async () => {
    setCompanyDataConfigs({
      ...companyDataConfigs,
      dataLoading: loadingStates.LOADING,
    });
    const resp = await apiWrapWithErrorWithData(companyDataApi());
    if (resp?.success) {
      setCompanyDataConfigs((prevValue) => ({
        ...prevValue,
        dataLoading: loadingStates.FINISHED,
        companyData: resp.data,
      }));
    } else {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load Company data.',
      });
    }
  };
  useEffect(() => {
    getCompanyData();
  }, []);
  const handleClose = () => {
    navigate('/company-data');
  };
  return (
    <div
      className={CompanyBookDetailsOverlayShow || BonusPoolDetailsOverlayShow || BalanceSheetDetailsOverlayShow ? `w-full flex h-full ${commonStyle.modalOverlay}` : 'w-full flex'}
      // className="w-full flex h-full"
      aria-hidden="true"
      onClick={handleClose}

    >
      <Layout>

        <div className={`flex flex-col w-full h-full ${commonStyle.rightSection}`}>
          <CompanyVisits data={companyDataConfigs.companyData} dataLoading={companyDataConfigs.dataLoading} />
        </div>
        {
        CompanyBookDetailsOverlayShow && (
          <Suspense>
            <CompanyBookDataDetails />
          </Suspense>
        )
      }
        {BonusPoolDetailsOverlayShow && (
        <Suspense>
          <BonusPoolDataDetails />
        </Suspense>
        )}
        {BalanceSheetDetailsOverlayShow && (
        <Suspense>
          <BalanceSheetDetails />
        </Suspense>
        )}
      </Layout>
    </div>

  );
}
export default CompanyData;
