import React from 'react';
import {
  string,
} from 'prop-types';
import styles from './RoundIcon.module.css';
import { assetPrefix } from '../../utilities/general';

const RoundIcon = (props) => {
  const {
    src, title,
  } = props;

  return (
    <div
      className={`relative ${styles.roundIconBorder}`}
    >
      <img
        referrerPolicy="no-referrer"
        src={(src && src !== null && src.length !== 0) ? src : `${assetPrefix}/images/Avatar.svg`}
        alt={title}
        className={styles.roundIcon}
      />
    </div>
  );
};

RoundIcon.propTypes = {
  src: string.isRequired,
  title: string.isRequired,
};

export default RoundIcon;
