/* eslint-disable max-len */
import React from 'react';
import { LayoutGridAdd } from 'tabler-icons-react';
import { useMatch, useNavigate } from 'react-router';
import { useMantineColorScheme } from '@mantine/core';
import Header from './Header';
import styles from './Layout.module.css';
import routes from '../../router/routes';
import { buttonize } from '../../utilities/general';

const Layout = ({ children }) => {
  const { colorScheme } = useMantineColorScheme();

  const matchTraderListing = !!useMatch(routes.TRADERS_DATA_LISTING);
  const matchTraderDetails = !!useMatch(routes.TRADERS_DATA_DETAIL);
  // const matchBalanceSheet = !!useMatch(routes.BALANCE_SHEET);
  // const matchCompanyData = !!useMatch(routes.COMPANY_DATA);
  const matchCompanyDataV2 = !!useMatch(routes.COMPANY_DATA_V2);
  // const matchFormData = !!useMatch(routes.FORM);
  // const matchSettings = !!useMatch(routes.SETTINGS);

  const sidebarItems = [{
    Icon: LayoutGridAdd,
    label: 'Trader Data',
    route: routes.TRADERS_DATA_LISTING,
    isActive: matchTraderListing || matchTraderDetails,
    barColor: '#F9A61A',
  },
  // {
  //   Icon: LayoutGridAdd,
  //   label: 'Company Data',
  //   route: routes.COMPANY_DATA,
  //   isActive: matchCompanyData,
  //   barColor: '#F9A61A',
  // },
  {
    Icon: LayoutGridAdd,
    label: 'Company Data',
    route: routes.COMPANY_DATA_V2,
    isActive: matchCompanyDataV2,
    barColor: '#F9A61A',
  },
    // {
    //   Icon: LayoutGridAdd,
    //   label: 'Liabilities & Assets',
    //   route: routes.FORM,
    //   isActive: matchFormData,
    //   barColor: '#F9A61A',
    // },
    // {
    //   Icon: LayoutGridAdd,
    //   label: 'Settings',
    //   route: routes.SETTINGS,
    //   isActive: matchSettings,
    //   barColor: '#F9A61A',
    // },
    // , {
    //   Icon: Table,
    //   label: 'Balance Sheet',
    //   route: routes.BALANCE_SHEET,
    //   isActive: matchBalanceSheet,
    //   barColor: '#F9A61A',
    // }
  ];

  const firstActiveRoute = sidebarItems.find((item) => item.isActive);

  const navigate = useNavigate();

  return (
    <div className={`h-screen w-screen flex flex-col ${styles.mainContainer} ${colorScheme === 'dark' && styles.darkBody}`}>
      <Header />
      <div
        className={`w-full ${styles.colorBar}`}
        style={{
          background: firstActiveRoute?.barColor || '#F9A61A',
        }}
      >
        &nbsp;
      </div>
      <div className={`grid ${styles.layoutContainer} h-screen`}>
        <div className={`${styles.sidebar}`}>
          {sidebarItems.map(
            (item) => (
              <div
                key={item.label}
                className={`${styles.sidebarItem} ${item.isActive ? styles.sidebarActive : ''}`}
                {...buttonize(() => {
                  setTimeout(() => {
                    navigate(item.route);
                  }, 0);
                })}
              >
                <div className={`${styles.sidebarIcon}`}>
                  <item.Icon />
                </div>
                <span>
                  {item.label}
                </span>
              </div>
            ),
          )}

        </div>
        <div className={styles.mainBody}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
