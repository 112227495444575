/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import styles from '../CompanyDataV2.module.css';
import { loadingStates, renderNumber } from '../../../../utilities/general';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { V2bonusPoolAnalyticsApi, V2bonusPoolChartsApi, balanceSheetFinancialApi } from '../../../../utilities/apis/companyData';
import LineCharts from '../LineChart';
import BarCharts from '../BarChart';
import StatsCard from '../StatsCard';
import InvestmentLineCharts from '../BalanceSheet/InvestmentLineChart';
import { InvestmentDummy } from '../BalanceSheet/dummy';
import InvestmentListing from '../BalanceSheet/InvestmentListing';
import PieChart from './PieChart';

export default function BonusPool() {
  const [analyticsData, setAnalyticsData] = useState();
  const [chartData, setChartData] = useState();

  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);

  const bonusPoolAnalyticsData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(V2bonusPoolAnalyticsApi());

    if (resp?.success && resp?.data) {
      setAnalyticsData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet financial data',
        title: 'Error while fetching balance-sheet financial Data',
      });
    }
  };
  const bonusPoolChartsData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(V2bonusPoolChartsApi());

    if (resp?.success && resp?.data) {
      setChartData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet financial data',
        title: 'Error while fetching balance-sheet financial Data',
      });
    }
  };
  useEffect(() => {
    bonusPoolAnalyticsData();
    bonusPoolChartsData();
  }, []);
  const states = analyticsData?.statsLastCycle;
  const currentPool = analyticsData?.currentPoolCommissions;
  const statsData = [
    {
      label: 'Total Trades',
      value: states?.totalTrades,
      sections: [
        { value: (states?.profitableTrades / states?.totalTrades) * 100, color: 'green' },
        { value: (states?.losingTrades / states?.totalTrades) * 100, color: 'red' },
        { value: (states?.openTrades / states?.totalTrades) * 100, color: 'blue' },
      ],
    },
    {
      label: 'Profitable Trades',
      value: states?.profitableTrades,
      sections: [{ value: states?.profitableTrades, color: 'green' }],
    },
    {
      label: 'Losing Trades',
      value: states?.losingTrades,
      sections: [{ value: states?.losingTrades, color: 'red' }],
    },
    {
      label: 'Open Trades',
      value: states?.openTrades,
      sections: [{ value: states?.openTrades, color: 'blue' }],
    }];
  const BonusAndCommission = [
    {
      label: 'Current Pool - Bonus',
      value: currentPool?.currenPoolBonus,
      sections: [
        { value: (currentPool?.traderCommission / currentPool?.currenPoolBonus) * 100, color: 'green' },
        { value: (currentPool?.teamCommission / currentPool?.currenPoolBonus) * 100, color: 'red' },
        { value: (currentPool?.investorCommission / currentPool?.currenPoolBonus) * 100, color: 'blue' },
      ],
    },
    {
      label: 'Current Pool - Trader Commision',
      value: currentPool?.traderCommission,
      sections: [{ value: (currentPool?.traderCommission / currentPool?.currenPoolBonus) * 100, color: 'green' }],
    },
    {
      label: 'Current Pool - Team Commission',
      value: currentPool?.teamCommission,
      sections: [{ value: (currentPool?.teamCommission / currentPool?.currenPoolBonus) * 100, color: 'red' }],
    },
    {
      label: 'Current Pool - Investor Commission',
      value: currentPool?.investorCommission,
      sections: [{ value: (currentPool?.investorCommission / currentPool?.currenPoolBonus) * 100, color: 'blue' }],
    }];
  return (
    <div>
      <div className="p-12">
        <div className={styles.bonusPoolDataLayout}>

          {/* First row */}
          <div className={styles.pnlCard}>
            <LineCharts
              chartName="Revenue Generation"
              loading="FINISHED"
              total={chartData?.revenueOneMonth?.average}
              changeInPercentage={chartData?.revenueOneMonth?.changeInPercentage}
              days={30}
              data={chartData?.revenueOneMonthData}
            />
          </div>
          <div className={`${styles.winRate} !bg-[#003A6D]`}>
            <InvestmentLineCharts
              loading="FINISHED"
              chartName="Profit Generation"
              total={chartData?.profitOneMonth?.average}
              changeInPercentage={chartData?.profitOneMonth?.changeInPercentage}
              days={30}
              data={chartData?.profitOneMonthData}
            />
          </div>
          <div className={styles.valTotalAssets}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={`${styles.valueText} text-green-500`}>{renderNumber(analyticsData?.totalRevenue, true)}</span>
              <span className={`${styles.Text}`}>Total Revenue</span>
            </div>
          </div>
          <div className={styles.valTotalLiabilities}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={`${styles.valueText} text-red-500`}>{renderNumber(analyticsData?.totalPayouts, true)}</span>
              <span className={`${styles.Text}`}>Total Payouts</span>
            </div>
          </div>

          {/* 2nd row */}

          <div className={styles.pnlCard2}>
            <BarCharts
              data={chartData?.traderProfitOneMonthData}
              loading="FINISHED"
              chartName="Trader Profit "
              average={chartData?.traderProfitOneMonth?.average}
              days="30 Days"
              color={['#0093EF', '#E9EDF7']}
              // changeInPercentage={snapshotData?.dailyUnAllocatedValueSummary.changeInPercentage}
            />
          </div>
          <div className={styles.roiCard}>
            <BarCharts
              data={chartData?.profitProgressionSixMonthData}
              loading="FINISHED"
              chartName="Profit Progression"
              average={chartData?.profitProgressionSixMonth?.average}
              days="M-o-M - 6 Months"
              color={['#F9A61A', '#F8994238']}
            />
          </div>
          <div className={styles.valTotalFund}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={styles.valueText}>{renderNumber(analyticsData?.investorCommission, true)}</span>
              <span className={`${styles.Text}`}>Investor Commission</span>
            </div>
          </div>
          <div className={styles.valTotalCurrVal}>
            <div className="grid h-full w-full justify-center items-center p-8">
              <span className={styles.valueText}>{renderNumber(analyticsData?.internalCommission, true)}</span>
              <span className={`${styles.Text}`}>Internal Commission</span>
            </div>
          </div>

          {/* 3rd row */}

          <div className={`${styles.hotColdWallet}`}>
            <PieChart pieData={analyticsData?.shareableProfit} />
          </div>
          <div className={`${styles.statsCard}`}>
            <StatsCard title="Stats - Last Cycle" data={statsData} />
          </div>
          <div className={styles.profitsCard}>
            <StatsCard title="Current Bonus and Commissions" data={BonusAndCommission} />
          </div>

        </div>

      </div>
    </div>
  );
}
