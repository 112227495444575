import React, { useState } from 'react';
import { Tabs, useMantineColorScheme } from '@mantine/core';
import { useNavigate } from 'react-router';
import CompanyBook from './CompanyBook/CompanyBook';
import styles from './CompanyDataV2.module.css';
import BalanceSheet from './BalanceSheet/BalanceSheet';
import { assetPrefix, buttonize } from '../../../utilities/general';
import BonusPool from './BonusPool/BonusPool';

const CompanyDataNavBar = () => {
  const [activeTab, setActiveTab] = useState('companyBook');
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  return (
    <div className={colorScheme === 'dark' && styles.dark}>
      <Tabs
        value={activeTab}
        onTabChange={setActiveTab}
        sx={{
          '.mantine-Tabs-tab[data-active="true"]': {
            color: '#026DB0',
            borderBottom: '6px solid #33B1FF',
            borderBottomRightRadius: '7px',
            borderBottomLeftRadius: activeTab === 'companyBook' ? '0px' : '7px',
            fontWeight: 800,
            paddingTop: 15,
            background: colorScheme === 'dark' ? 'none' : 'var(--NEWTAB-GD, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F4FBFE 100%))',
          },
          '.mantine-Tabs-tab': {
            color: '#A3AED0',
            fontFamily: 'SF Pro Display',
            fontSize: '1.0625rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.5rem',
          },
        }}
      >
        <Tabs.List className={`${colorScheme === 'dark' ? 'bg-black' : 'bg-white'} h-16 sticky top-0 z-10`}>
          <Tabs.Tab value="companyBook">Company Book</Tabs.Tab>
          <Tabs.Tab value="balanceSheet" className="hidden">Balance Sheet</Tabs.Tab>
          <Tabs.Tab value="bonusPool" className="hidden">Bonus Pool</Tabs.Tab>
          <div className={styles.detailsButtonContainer}>
            <div
              className={styles.detailsButton}
              {...buttonize((e) => {
                e.stopPropagation();
                if (activeTab === 'companyBook') {
                  navigate('/company-book-details');
                } else if (activeTab === 'balanceSheet') {
                  navigate('/balance-sheet-details');
                } else {
                  navigate('/bonusPool-data-details');
                }
              })}
            >
              <img
                src={`${assetPrefix}/images/expand.svg`}
                alt="expand"
                className="h-5 w-5"
              />
              View Details
            </div>
          </div>
        </Tabs.List>

        <Tabs.Panel value="companyBook"><CompanyBook /></Tabs.Panel>
        <Tabs.Panel value="balanceSheet"><BalanceSheet /></Tabs.Panel>
        <Tabs.Panel value="bonusPool"><BonusPool /></Tabs.Panel>

      </Tabs>
    </div>
  );
};

export default CompanyDataNavBar;
