import React, { useEffect, useState } from 'react';
import { Tabs, useMantineColorScheme } from '@mantine/core';
// import ContentManagement from './ContentManagement';
import { showNotification } from '@mantine/notifications';
import UserAuthorization from './UserAuthorization';
import AccessControl from './AccessControl';
import { apiWrapWithErrorWithData } from '../../../utilities/apis/apiHelpers';
import { getAccessLevelsApi } from '../../../utilities/apis/setting';
// import { useNavigate } from 'react-router';

const SettingNavBar = () => {
  const [activeTab, setActiveTab] = useState('userAuthorization');
  const [accessLevels, setAccessLevels] = useState();
  const { colorScheme } = useMantineColorScheme();
  const getAccessLevelsData = async () => {
    const resp = await apiWrapWithErrorWithData(getAccessLevelsApi());
    if (resp?.success) {
      setAccessLevels(resp.data);
    } else {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Failed to load Access levels data.',
      });
    }
  };
  useEffect(() => {
    getAccessLevelsData();
  }, []);

  return (
    <div className={`${colorScheme === 'dark' ? 'dark' : ''}`}>
      <Tabs
        value={activeTab}
        onTabChange={setActiveTab}
        sx={{
          '.mantine-Tabs-tab[data-active="true"]': {
            color: '#026DB0',
            borderBottom: '6px solid #33B1FF',
            borderBottomRightRadius: '7px',
            borderBottomLeftRadius: activeTab === 'contentManagement' ? '0px' : '7px',
            fontWeight: 800,
            paddingTop: 15,
            background: colorScheme === 'dark' ? 'none' : 'var(--NEWTAB-GD, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F4FBFE 100%))',
          },
          '.mantine-Tabs-tab': {
            color: '#A3AED0',
            fontFamily: 'SF Pro Display',
            fontSize: '1.0625rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.5rem',
          },
        }}
      >
        <Tabs.List className="bg-white dark:bg-black h-16 sticky top-0 z-10">
          {/* <Tabs.Tab value="contentManagement">Content Management</Tabs.Tab> */}
          <Tabs.Tab value="userAuthorization">User Authorization</Tabs.Tab>
          {/* <Tabs.Tab value="Security">Security</Tabs.TabaccessSettings> */}
          <Tabs.Tab value="accessControl">Access Control</Tabs.Tab>
        </Tabs.List>

        {/* <Tabs.Panel value="contentManagement"><ContentManagement /></Tabs.Panel> */}
        <Tabs.Panel value="userAuthorization"><UserAuthorization authData={accessLevels?.accessSettings} /></Tabs.Panel>
        {/* <Tabs.Panel value="Security">Security</Tabs.Panel> */}
        <Tabs.Panel value="accessControl"><AccessControl accessData={accessLevels?.userDetails} /></Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default SettingNavBar;
