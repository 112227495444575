import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { selectLoggedInStatus } from '../redux/selectors';

const Protected = ({ children }) => {
  const location = useLocation();
  const loggedInStatus = useSelector(selectLoggedInStatus, () => {});
  if (loggedInStatus.loggedIn) {
    return children;
  }
  return (
    <Navigate
      to="/"
      replace
      state={{
        from: location.pathname,
      }}
    />
  );
};

export default Protected;
