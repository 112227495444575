/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Details } from 'tabler-icons-react';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Label,
} from 'recharts';
import { useNavigate } from 'react-router';
import Styles from './common.module.css';
import {
  assetPrefix, buttonize, loadingStates, renderNumber,
} from '../../utilities/general';
import FlashLoader from '../CommonFunction/FlashLoader';

export default function CompanyBooks({ data, dataLoading }) {
  const navigate = useNavigate();
  const fillPercentage = data?.winRate ? data?.winRate * 100 : 0;
  const chartData = [
    { name: 'Fill', value: fillPercentage },
    { name: 'Empty', value: 100 - fillPercentage },
  ];
  const COLORS = ['#026DB0', '#EFEFEF'];

  return (
    <div className={`${Styles.CompanyContainer}`}>
      <div className="flex gap-16 w-[100%] h-[6rem] border-b-2 pl-9 items-center">
        <div className="flex gap-4">
          <Details
            size={25}
            strokeWidth={2}
            color="black"
          />
          <span className={Styles.HeadingText}>Company Book</span>
        </div>
        <span
          {...buttonize((e) => {
            e.stopPropagation();
            navigate('/company-book-details');
          })}
          className="grid items-center"
        >
          <img src={`${assetPrefix}/images/circleArrow.svg`} alt="content" width={36} height={36} />
        </span>
      </div>

      <div>
        { dataLoading === loadingStates.FINISHED
      && (
        <>
          <div className="flex pt-6 pb-5 justify-center demo">
            <div className="grid gap-10 grid-cols-2">
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Total Book value</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>{`${renderNumber(data?.totalBookValue, true)}`}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Running PnL</span>
                <span className={`${Styles.Text} ${data?.runningPnL < 0 ? 'text-red-500' : 'text-lime-600'}`}>{`${renderNumber(data?.runningPnL, true)}`}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Current Book Value</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>{`${renderNumber(data?.currentBookValue, true)}`}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>No of Open Trades</span>
                <span className={`${Styles.Text} text-[#003A6D]`}>
                  {`${renderNumber(data?.numberOfOpenTrades)} Trades`}
                </span>
              </div>
              <div className={`${Styles.LastDaysBox} col-span-2 w-full flex justify-center`}>
                <div className={`${Styles.LastDays} max-w-max flex justify-center`}>In the last 30 days</div>
              </div>

              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Realized Profit</span>
                <span className={`${Styles.Text} text-lime-600`}>{`${renderNumber(data?.realizedProfits30days, true)}`}</span>
              </div>
              <div className="grid">
                <span className={`${Styles.MiniText} pb-2`}>Return Rate</span>
                <span className={`${Styles.Text}  ${data?.returnRate30days < 0 ? 'text-red-500' : 'text-lime-600'}`}>{ `${renderNumber(data?.returnRate30days * 100)}%`}</span>
              </div>
            </div>
          </div>
          <div className="pt-0 h-full">
            <ResponsiveContainer width="100%" height={129}>
              <PieChart
                margin={{
                  top: 120,
                }}
              >
                <Label value="value" position="center" />
                <Pie
                  data={chartData}
                  dataKey="value"
                  startAngle={180}
                  endAngle={0}
                  cx="50%"
                  cy="90%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  labelLine={false}
                  background={{ fill: '#eee' }}
                  blendStroke
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  {/* <Label value="59" position="centerBottom" fontSize={20} /> */}

                </Pie>
                <text
                  x="50%"
                  y="74%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="#3A3A3A"
                  fontSize={16}
                  fontWeight={500}
                >
                  Win rate
                </text>
                <text
                  x="50.3%"
                  y="90%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="#026DB0"
                  fontSize={20}
                  fontWeight={700}
                >
                  {`${renderNumber(fillPercentage)}%`}
                </text>
              </PieChart>
            </ResponsiveContainer>
          </div>

        </>
      )}
        { dataLoading === loadingStates.LOADING
      && (<div className="flex justify-center items-center min-h-[371px] xl:min-h-[407px] 2xl:min-h-[498px]"><FlashLoader /></div>)}
      </div>

    </div>
  );
}
