/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer,
  Sector, Text, Tooltip, XAxis, YAxis,
} from 'recharts';
import { showNotification } from '@mantine/notifications';
import './BalanceSheet.module.css';
import { Loader } from '@mantine/core';
import FinancialListing from './FinancialListing';
import OverviewContainer from '../../CommonDetailsComponent/OverviewContainer';
import Styles from '../../CommonDetailsComponent/TradersDetails.module.css';
import { apiWrapWithErrorWithData } from '../../../../utilities/apis/apiHelpers';
import { balanceSheetFinancialApi } from '../../../../utilities/apis/companyData';
import { loadingStates, renderNumber } from '../../../../utilities/general';
import FlashLoader from '../../../../components/CommonFunction/FlashLoader';

export default function FinancialTab() {
  const [financialData, setFinancialData] = useState();
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);

  const topRoi = financialData?.allAssetsList;
  const topQuantity = financialData?.allAssetsList;
  const fetchBalanceSheetFinancialData = async () => {
    setDataLoading(loadingStates.LOADING);
    const resp = await apiWrapWithErrorWithData(balanceSheetFinancialApi());

    if (resp?.success && resp?.data) {
      setFinancialData(resp.data);
      setDataLoading(loadingStates.FINISHED);
    } else {
      showNotification({
        color: 'red',
        message: 'Couldn\'t load Company balance-sheet financial data',
        title: 'Error while fetching balance-sheet financial Data',
      });
    }
  };
  useEffect(() => {
    fetchBalanceSheetFinancialData();
  }, []);

  topRoi?.sort((a, b) => {
    const roiPercentageA = a.roiPercentage || -Infinity;
    const roiPercentageB = b.roiPercentage || -Infinity;
    return roiPercentageB - roiPercentageA;
  });
  const sortedRoiData = topRoi?.slice(0, 5).map(({ roiPercentage, assetName }) => ({ roiPercentage, assetName }));

  topQuantity?.sort((a, b) => {
    const quantityA = a.currentValue || -Infinity;
    const quantityB = b.currentValue || -Infinity;
    return quantityB - quantityA;
  });
  const sortedQuantityData = topQuantity?.slice(0, 5)
    .map(({ currentValue, assetName }) => ({ currentValue, assetName }));

  const totalRevenue = sortedQuantityData?.reduce((sum, entry) => sum + entry?.currentValue, 0);

  const YAxisLeftTick = ({ y, payload: { value } }) => (
    <Text x={0} y={y} textAnchor="start" className="text-sm font-normal text-[#333333] dark:fill-neutral-400">
      {value}
    </Text>
  );
  // const YAxisRightTick = ({ y, payload: { value } }) => (
  //   <Text x={0} y={y}>
  //     {value}
  //   </Text>
  // );
  // const renderLegend = (props) => {
  //   const { payload } = props;

  //   return (
  //     <ul>
  //       {
  //         payload.map((entry, index) => (
  //           <>
  //             <li key={`item-${index}`} style={{ color: entry.color }}
  // className="font-normal text-sm"><span>{entry.value}</span></li>
  //             <div key={`item-${index}`} className="text-lg font-normal
  //  text-[#243E60]">{entry.payload.pr}</div>
  //             {console.log(entry)}
  //           </>
  //         ))
  //       }
  //     </ul>
  //   );
  // };
  // const COLORS = ['#026DB0', '#33B1FF', '#78C9FB', '#E7F6FF', '#F9A61A'];
  const [activeIndex, setActiveIndex] = useState();

  const COLORS = ['#003A6D', '#D3EBFA', '#026DB0', '#49A1EC', '#30BEF5'];
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  const onPieLeave = useCallback(
    () => {
      setActiveIndex(undefined);
    },
    [setActiveIndex],
  );
  const renderActiveShape = (items) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = items;

    return (
      <g style={{ backgroundColor: 'red' }}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 10}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const CustomTooltip = ({ payload }) => (
    <div className="bg-orange-400 px-2 text-white">
      {`${payload[0]?.payload?.assetName} : ${renderNumber(payload[0]?.payload?.currentValue, true)}`}

    </div>
  );
  const renderLegend = (item) => {
    const { payload } = item;
    return (
      <>
        {
          payload?.map((entry, index) => (
            <div className="flex items-center gap-0.5">
              <span
                style={{
                  backgroundColor: entry.color,
                }}
                className="h-3.5 w-3.5 rounded-[3px]"
              />
              <span key={`item-${index}`} className={`${Styles.LegendText} dark:text-inherit`}>
                {entry?.payload?.assetName}
              </span>
            </div>
          ))
        }
      </>
    );
  };
  return (
    <div className="border border-[#CBD3E0] dark:border-neutral-800 rounded-xl mx-8 my-8">
      {dataLoading === loadingStates.LOADING && (
      <div className="h-screen">
        <FlashLoader />
      </div>
      )}
      {dataLoading === loadingStates.FINISHED
      && (
      <>
        <div className="mt-3 mx-6">
          <div className="flex w-full gap-3 pb-10">
            <div className="w-full"><OverviewContainer type="Total Investment" iconName="businessplan" totalValue={financialData?.totalInvestment?.value} changeInPercentage={financialData?.totalInvestment?.changeInPercentage} dataLoading={dataLoading} /></div>
            <div className="w-full"><OverviewContainer type="Total Profit/Loss" iconName="timeline" totalValue={financialData?.totalPnL?.value} changeInPercentage={financialData?.totalPnL?.changeInPercentage} dataLoading={dataLoading} /></div>
          </div>
          <div className="flex flex-cols w-[100%] ml-[inherit] divide-x-2 dark:divide-neutral-600 ">
            <div className="flex flex-col w-[18%] gap-2">
              <span className="font-light text-base font-[Regola Pro Trial]">
                Total Asset Value
              </span>
              <span className="font-semibold text-3xl text-[#026DB0]">{renderNumber(financialData?.totalAssetValue, true)}</span>
            </div>
            <div className="flex flex-col items-center w-[20%] gap-2">
              <span className="font-light text-base font-[Regola Pro Trial]">Book Value - Hot</span>
              <span className="font-semibold text-3xl text-[#243E60]">{renderNumber(financialData?.bookValueHot, true)}</span>
            </div>
            <div className="flex flex-col items-center w-[20%] gap-2">
              <span className="font-light text-base font-[Regola Pro Trial]">Hot Value - Delta</span>
              <span className={`font-semibold text-3xl ${financialData?.hotValueDelta > 0 ? 'text-lime-600' : 'text-red-500'}`}>{renderNumber(financialData?.hotValueDelta, true)}</span>
            </div>
            <div className="flex flex-col items-center w-[20%] gap-2">
              <span className="font-light text-base font-[Regola Pro Trial]">Book Value - Cold</span>
              <span className="font-semibold text-3xl text-[#243E60]">{renderNumber(financialData?.bookValueCold, true)}</span>
            </div>
            <div className="flex flex-col items-center w-[20%] gap-2">
              <span className="font-light text-base font-[Regola Pro Trial]">Cold Value - Delta</span>
              <span className={`font-semibold text-3xl ${financialData?.coldValueDelta > 0 ? 'text-lime-600' : 'text-red-500'}`}>{renderNumber(financialData?.coldValueDelta, true)}</span>
            </div>
          </div>
        </div>
        <div className="mx-6 my-10 border border-slate-100 dark:border-neutral-600">
          <div className="h-[100%] w-[100%] flex divide-x divide-slate-100 dark:divide-neutral-600">
            <div className="w-[100%]">
              <div className="grid mt-5 mx-8">
                <span className={`flex items-baseline dark:text-inherit ${Styles.HeadingText}`}>
                  Top Asset RoIs
                </span>
                {/* <span className="font-normal text-sm text-[#757575]">
              Subinfo about data represented on the chart (optional)</span> */}
              </div>
              <div className="mt-5 ml-8 mr-2">
                {dataLoading === loadingStates.LOADING
              && <div className="flex min-h-[29.7rem] justify-center items-center"><Loader variant="dots" /></div>}
                {dataLoading === loadingStates.FINISHED && (
                <div className="w-full">
                  <ResponsiveContainer width="99%" aspect={1.2}>
                    <BarChart
                      layout="vertical"
                      // barCategoryGap={0}
                      data={sortedRoiData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 30,
                        bottom: 40,
                      }}
                    >
                      <YAxis
                        yAxisId={0}
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        dataKey="assetName"
                        tick={YAxisLeftTick}
                      />
                      <YAxis
                        yAxisId={1}
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        dataKey="roiPercentage"
                        orientation="right"
                        tickFormatter={(tick) => `${Number(tick * 100).toFixed(2)}%`}
                      />
                      <Tooltip formatter={(props) => (`${(props * 100).toFixed(2)}%`)} />
                      <XAxis hide type="number" />
                      <Bar
                        dataKey="roiPercentage"
                        barSize={30}
                        fill="#026DB0E5"
                        background={{ fill: '#E4ECF1' }}
                        minPointSize={15}
                      />

                    </BarChart>
                  </ResponsiveContainer>
                </div>
                )}
              </div>
            </div>
            <div className="w-[100%]">
              <div className="grid mt-5 mx-8">
                <span className={`flex items-baseline dark:text-inherit ${Styles.HeadingText}`}>
                  Top Asset by Current Value and Splits
                </span>
                {/* <span className="font-normal text-sm text-[#757575]">
              Subinfo about data represented on the chart (optional)</span> */}
              </div>
              <div className="mt-0 pb-0">
                {dataLoading === loadingStates.LOADING
              && <div className="flex min-h-[29.7rem] justify-center items-center"><Loader variant="dots" /></div>}
                {dataLoading === loadingStates.FINISHED
              && (
              <div>
                <div style={{ width: '99%', height: '30.90rem' }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Legend
                        content={renderLegend}
                        verticalAlign="top"
                        align="left"
                        wrapperStyle={{
                          margin: '0.213rem 2rem',
                          top: '0.625rem',
                          paddingBottom: '0px',
                          display: 'flex',
                          gap: 18,
                          alignItems: 'flex-start',
                          alignSelf: 'stretch',
                        }}
                        iconType="square"
                        iconSize={12}
                      />
                      <Pie
                        data={sortedQuantityData}
                        dataKey="currentValue"
                        innerRadius={90}
                        outerRadius={145}
                        fill="#8884d8"
                        legendType="square"
                        blendStroke
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={onPieEnter}
                        onMouseLeave={onPieLeave}
                      >
                        {sortedQuantityData?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip content={CustomTooltip} />
                      <text
                        x="50%"
                        y="52%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#58667E"
                        fontSize={28}
                        fontWeight={700}
                        fontFamily="SF Pro Display"
                      >
                        {renderNumber(totalRevenue, true)}
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              )}
              </div>
            </div>
          </div>
          {/* <div className="w-[50%] h-[100%]">
          <ProfitLossChart ProfitLossDummy={ProfitLosssDummy} /></div> */}
        </div>

        <div className="mt-0 w-full">
          <FinancialListing data={financialData?.allAssetsList} dataLoading={dataLoading} />
        </div>
      </>
      )}
    </div>
  );
}
