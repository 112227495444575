import React from 'react';
import { ArrowDown, ArrowUp } from 'tabler-icons-react';
import { Loader } from '@mantine/core';
import Styles from './TradersDetails.module.css';
import { assetPrefix, loadingStates, renderNumber } from '../../../utilities/general';

export default function OverviewContainer(props) {
  const {
    type, iconName, totalValue, changeInPercentage, dataLoading,
  } = props;

  return (
    <div>
      <div className={`${Styles.overViewContainer} mt-4`}>
        <div className="w-[70px]"><img src={`${assetPrefix}/images/${iconName}.svg`} width={65} height={65} alt="SettingIcon" /></div>
        <div className={`${Styles.gridWidth} flex`}>
          <div className="grid w-full">
            <span className="text-sky-400 text-[18px] font-normal not-italic">{type}</span>
            <span className="text-white dark:text-slate-300 text-[32px] font-bold non-italic min-h-[48px]">
              {dataLoading === loadingStates.LOADING && (
              <div className="flex h-full items-center">
                <Loader variant="dots" />
              </div>
              )}
              {dataLoading === loadingStates.FINISHED && renderNumber(totalValue, true)}
            </span>
          </div>
          <div className="flex justify-end">
            {dataLoading === loadingStates.LOADING && <div className="flex items-center pt-8 pr-4"><Loader variant="dots" /></div>}
            {dataLoading === loadingStates.FINISHED
            && (
            <div className="flex items-center gap-1 pt-8 pr-4">
              <span>
                {(changeInPercentage > 0)
                  ? (<ArrowUp color="#06AA8D" />)
                  : (<ArrowDown color="red" />)}
              </span>
              <span className="font-medium flex items-center min-w-[26px] text-[22px] text-white dark:text-slate-300">
                {`${renderNumber(changeInPercentage * 100)}%`}
              </span>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
