import { useMemo, useState } from 'react';

const useSorting = (items, text = null) => {
  const [sortConfig, setSortConfig] = useState({ key: text, direction: 'ascending' });
  const first = sortConfig.key && sortConfig?.key?.split('.')[0];
  const second = sortConfig.key && sortConfig?.key?.split('.')[1];

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig
      && sortConfig.key === key
      && sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = useMemo(() => {
    const sortableItems = items ? [...items] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.direction === 'ascending') {
          return (!sortConfig.key.includes('.') ? b[sortConfig.key] : b[first][second]) < (!sortConfig.key.includes('.') ? a[sortConfig.key] : a[first][second]) ? -1 : 1;
        }
        if (sortConfig.direction === 'descending') {
          return (!sortConfig.key.includes('.') ? b[sortConfig.key] : b[first][second]) > (!sortConfig.key.includes('.') ? a[sortConfig.key] : a[first][second]) ? -1 : 1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSorting;
